import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Menu, MenuItem } from '@mui/material'
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const DropdownDownload = ({ sx, handleDownload, downloading, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDownloadFormat = format => {
    handleDownload(format)
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        sx={sx}
        disabled={disabled}
        id="basic-button"
        variant="outlined"
        aria-controls={open ? 'download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={
          downloading ? (
            <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} />
          ) : (
            <FileDownloadOutlinedIcon fontSize="medium" />
          )
        }
        onClick={handleClick}>
        {downloading ? 'Descargando' : 'Descargar'}
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleDownloadFormat('pdf')}>
          <PictureAsPdfOutlinedIcon sx={{ marginRight: '.5rem', color: '#555' }} />
          PDF
        </MenuItem>
        <MenuItem onClick={() => handleDownloadFormat('csv')}>
          <TableViewOutlinedIcon sx={{ marginRight: '.5rem', color: '#555' }} />
          CSV
        </MenuItem>
      </Menu>
    </div>
  )
}

DropdownDownload.propTypes = {
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  downloading: PropTypes.bool,
  handleDownload: PropTypes.func
}

export default DropdownDownload
