import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { Box, Divider, FormControl, IconButton } from '@mui/material'
import SideModal from 'components/SideModal/SideModal'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DropdownUsersMultiple from 'components/DropdownUsersMultiple/DropdownUsersMultiple'
import ProjectService from 'api/services/project.service'
import DropdownProjectRoles from 'components/DropdownProjectRoles/DropdownProjectRoles'
import { projectUsersSchema } from 'utils/schemas/projectUsersSchema'
import Styles from './_SideModalManageUsers.module.scss'

const SideModalManageUsers = ({ open, onCancel, onConfirm, selectedUsers, projectId }) => {
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ assigned_users: [] })

  const handleSaveProjectUsers = async values => {
    try {
      const body = values.assigned_users.map(user => {
        return {
          id: user.id,
          project_roles: user.project_roles.map(role => role.id)
        }
      })
      const resp = await ProjectService.updateUsers(projectId, { users: body })
      setLoading(false)
      onConfirm('success', resp)
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }

  const { errors, touched, values, isValid, dirty, handleSubmit, setFieldValue, setFieldTouched, resetForm } =
    useFormik({
      initialValues: form,
      enableReinitialize: true,
      validationSchema: projectUsersSchema,
      onSubmit: vals => {
        setLoading(true)
        handleSaveProjectUsers(vals)
      }
    })

  const handleUsersChange = value => {
    setFieldValue('assigned_users', value)
  }

  const handleRemoveUser = index => {
    setFieldValue(
      'assigned_users',
      values.assigned_users.filter((item, i) => i !== index)
    )
  }

  const handleChangeProjectRoles = (index, value) => {
    setFieldValue(
      'assigned_users',
      values.assigned_users.map((item, i) => (i === index ? { ...item, project_roles: value } : item))
    )
  }

  useEffect(() => {
    setDisabled(!(isValid && dirty))
  }, [dirty, isValid])

  useEffect(() => {
    if (open) {
      setForm({ assigned_users: selectedUsers })
      resetForm()
    }
  }, [open])

  return (
    <SideModal
      loading={loading}
      disabled={disabled}
      title="Gestionar Usuarios"
      onConfirmText="Guardar cambios"
      open={open}
      onCancel={onCancel}
      onConfirm={() => handleSubmit()}>
      <>
        <FormControl className={Styles.input}>
          <DropdownUsersMultiple
            error={Boolean(values.assigned_users.length === 0 && touched.assigned_users && errors.assigned_users)}
            helperText={values.assigned_users.length === 0 && touched.assigned_users && errors.assigned_users}
            onBlur={() => setFieldTouched('assigned_users', true)}
            onChange={(e, value) => handleUsersChange(value)}
            value={values.assigned_users}
          />
        </FormControl>
        <Divider />
        {values.assigned_users.map((user, index) => {
          return (
            <Box key={index} className={Styles.user__item}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Box>
                  {user.name} {user.last_name}
                </Box>
                <IconButton onClick={() => handleRemoveUser(index)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <FormControl className={Styles.input}>
                  <DropdownProjectRoles
                    enableTags
                    error={Boolean(
                      touched?.assigned_users?.length > 0
                        ? errors?.assigned_users?.length > 0 &&
                            touched?.assigned_users[index]?.project_roles &&
                            errors?.assigned_users[index]?.project_roles
                        : errors?.assigned_users?.length > 0 && errors?.assigned_users[index]?.project_roles
                    )}
                    helperText={
                      touched?.assigned_users?.length > 0
                        ? errors?.assigned_users?.length > 0 &&
                          touched?.assigned_users[index]?.project_roles &&
                          errors?.assigned_users[index]?.project_roles
                        : errors?.assigned_users?.length > 0 && errors?.assigned_users[index]?.project_roles
                    }
                    value={user.project_roles}
                    onChange={(e, value, reason) => handleChangeProjectRoles(index, value, reason)}
                    onBlur={() => setFieldTouched(`assigned_users[${index}].project_roles`, true)}
                  />
                </FormControl>
              </Box>
            </Box>
          )
        })}
      </>
    </SideModal>
  )
}

SideModalManageUsers.propTypes = {
  open: PropTypes.bool,
  selectedUsers: PropTypes.array,
  projectId: PropTypes.number,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalManageUsers
