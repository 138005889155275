import { useDispatch } from 'react-redux'
import { setAlert as setAlertPopup, setCloseAlert } from '../reduxStore/reducer/alertManagementSlice'

export const useAlert = () => {
  const dispatch = useDispatch()
  const setAlert = (open, severity, message) => {
    dispatch(setAlertPopup({ open, severity, message }))
  }

  const handleClose = async () => {
    dispatch(setCloseAlert())
  }

  return { handleClose, setAlert }
}
