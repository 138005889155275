import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material'
import { useAuth } from 'hooks/useAuth'

const ModalInactivity = ({ open, onCancel }) => {
  const [counter, setCounter] = useState(60)
  const [loading, setLoading] = useState(false)
  const { handleLogout } = useAuth()

  const handleCloseSession = () => {
    setLoading(true)
    handleLogout()
  }

  useEffect(() => {
    counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : handleCloseSession()
  }, [counter])
  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
      <DialogTitle>Hemos detectado un periodo de inactividad</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <h3 style={{ textAlign: 'center', marginBottom: '.5rem' }}>Se cerrará tu sesión en {counter} segundos</h3>
        <h4 style={{ textAlign: 'center', marginTop: '.5rem' }}>¿Deseas mantener tu sesión activa?</h4>
      </DialogContent>
      <DialogActions sx={{ padding: '1.5rem', justifyContent: 'center' }}>
        <Button sx={{ width: '10rem' }} disabled={loading} variant="outlined" onClick={handleCloseSession}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Cerrar sesión'}
        </Button>
        <Button sx={{ width: '10rem' }} disabled={loading} variant="contained" onClick={() => onCancel()}>
          Mantener sesión
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalInactivity.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
}

export default ModalInactivity
