import * as Yup from 'yup'

export const phaseFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('El nombre es requerido')
    .test('no-general', 'La fase no de puede llamar General', value => value.trim().toUpperCase() !== 'GENERAL'),
  description: Yup.string().required('La descripción corta es requerida'),
  start_date: Yup.date().required('La fecha de inicio es requerida'),
  end_date: Yup.date().required('La fecha final contractual es requerida')
})
