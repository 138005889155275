export class Phase {
  id
  name
  description
  start_date
  end_date
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.description = ''
    this.start_date = null
    this.end_date = null
    this.project_id = 0
  }
}

export default Phase
