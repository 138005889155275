import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  Box,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Divider,
  IconButton,
  TableBody,
  Checkbox
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LineChart, useDrawingArea, useYScale } from '@mui/x-charts'
import RiskService from 'api/services/risk.service'
import DropdownUsers from 'components/DropdownUsers/DropdownUsers'
import Risk from 'models/Risk'
import { useAuth } from 'hooks/useAuth'
import { useProjectRoles } from 'hooks/useProjectRoles'
import {
  controlActions,
  impactArea,
  negativeStrategies,
  positiveStrategies,
  riskAreas,
  riskMode,
  riskPlanStatus,
  riskReminderFrequency,
  riskStatus,
  riskType,
  riskUrgency
} from 'utils/constants'
import { riskFormSchema } from 'utils/schemas/riskSchema'
import { riskColors } from 'utils/styles'
import Styles from './_ModalRisk.module.scss'

const ModalRisk = ({
  actionType,
  projectId,
  phases,
  selectedRisk,
  riskProbabilities,
  riskCostImpacts,
  riskTimeImpacts,
  riskSeverityLimits,
  open,
  onConfirm,
  onCancel
}) => {
  const { isSystemObserver } = useAuth()
  const { project: currentProject } = useOutletContext()
  const { isProjectObserver } = useProjectRoles()

  const [risk, setRisk] = useState({ ...new Risk(), project_id: projectId })
  const [probXcost, setProbXCost] = useState([])
  const [probXtime, setProbXTime] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedProbability, setSelectedProbability] = useState()
  const [selectedImpact, setSelectedImpact] = useState()
  const [riskDataLoaded, setRiskDataLoaded] = useState(false)
  const [riskHistory, setRiskHistory] = useState([])
  const [chars, setChars] = useState({
    title: 0,
    description: 0,
    visible_cause: 0,
    affectation: 0,
    action_plan: 0,
    tracking: 0
  })

  const handleSaveRisk = async values => {
    try {
      const phaseSaved =
        actionType === 'create' ? await RiskService.store({ ...values }) : await RiskService.update(risk.id, values)
      setLoading(false)
      onConfirm('success', phaseSaved)
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }
  const { errors, touched, values, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: risk,
    enableReinitialize: true,
    validationSchema: riskFormSchema,
    onSubmit: vals => {
      if (actionType !== 'view') {
        setLoading(true)
        handleSaveRisk(vals)
      }
    }
  })

  const getRiskHistory = async riskId => {
    const resp = await RiskService.findRiskHistory(riskId)
    setRiskHistory(resp)
  }

  const handleChangeRiskProbability = value => {
    setFieldValue('probability_score', value)
    const prob = riskProbabilities.find(item => item.score === value)
    setSelectedProbability(prob)
    if (selectedImpact) {
      setFieldValue('severity_value', (selectedImpact.value * prob.value).toFixed(2))
    }
  }

  const handleChangeRiskImpact = (value, isTimeImpact) => {
    setFieldValue('impact_score', value)
    const impact = isTimeImpact
      ? riskTimeImpacts.find(item => item.score === value)
      : riskCostImpacts.find(item => item.score === value)

    setSelectedImpact(impact)
    if (selectedProbability) setFieldValue('severity_value', (selectedProbability.value * impact.value).toFixed(2))
  }

  const handleCountChars = e => {
    setChars(prevState => ({ ...prevState, [e.target.name]: e.target.value.length }))
  }

  const handleHasDeadLine = checked => {
    setFieldValue('has_deadline', checked)
    setFieldValue('deadline_date', null)
    setFieldValue('deadline_days', '')
    setFieldValue('reminder_frequency', '')
  }

  const ColorPalette = () => {
    const { top, height, bottom } = useDrawingArea()
    const svgHeight = top + bottom + height

    const scale = useYScale() // You can provide the axis Id if you have multiple ones

    return (
      <defs>
        <linearGradient
          id="line_bar_color"
          x1="0"
          x2="0"
          y1="0"
          y2={`${svgHeight}px`}
          gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
        >
          <stop offset={scale(0.5) / svgHeight} stopColor={riskColors.red} stopOpacity={1} />
          <stop offset={scale(0.25) / svgHeight} stopColor={riskColors.red} stopOpacity={1} />
          <stop offset={scale(0.24) / svgHeight} stopColor={riskColors.yellow} stopOpacity={1} />
          <stop offset={scale(0.08) / svgHeight} stopColor={riskColors.yellow} stopOpacity={1} />
          <stop offset={scale(0.07) / svgHeight} stopColor={riskColors.green} stopOpacity={1} />
        </linearGradient>
      </defs>
    )
  }

  useEffect(() => {
    if (riskProbabilities && riskCostImpacts && riskTimeImpacts) {
      setRiskDataLoaded(true)
      const reverseArray = [...riskProbabilities]
      reverseArray.reverse()
      const matrixPxC = reverseArray.map(prob => {
        const items = riskCostImpacts.map(impact => {
          const value = (impact.value * prob.value).toFixed(2)
          return {
            value: value,
            color:
              value <= riskSeverityLimits.low
                ? riskColors.green
                : value <= riskSeverityLimits.medium
                  ? riskColors.yellow
                  : riskColors.red
          }
        })
        return {
          value: prob.value,
          score: prob.score,
          calculated_items: items
        }
      })
      setProbXCost(matrixPxC)

      const matrixPxT = reverseArray.map(prob => {
        const items = riskTimeImpacts.map(impact => {
          const value = (impact.value * prob.value).toFixed(2)
          return {
            value: value,
            color:
              value <= riskSeverityLimits.low
                ? riskColors.green
                : value <= riskSeverityLimits.medium
                  ? riskColors.yellow
                  : riskColors.red
          }
        })
        return {
          value: prob.value,
          score: prob.score,
          calculated_items: items
        }
      })
      setProbXTime(matrixPxT)
    }
  }, [riskProbabilities, riskCostImpacts, riskTimeImpacts])

  useEffect(() => {
    if (open) {
      setRiskHistory([])
      if (actionType === 'create') {
        setRisk({ ...new Risk(), project_id: projectId })
      } else {
        getRiskHistory(selectedRisk.id)
        Object.keys(chars).forEach(key => {
          setChars(prevState => ({ ...prevState, [key]: selectedRisk[key]?.length || 0 }))
        })
        setRisk({
          ...selectedRisk,
          creation_date: selectedRisk.creation_date ? dayjs(selectedRisk.creation_date) : null,
          implementation_date: selectedRisk.implementation_date ? dayjs(selectedRisk.implementation_date) : null,
          deadline_date: selectedRisk.deadline_date ? dayjs(selectedRisk.deadline_date) : null,
          has_deadline: selectedRisk.has_deadline === 1,
          tracking: selectedRisk.tracking || '',
          visible_cause: selectedRisk.visible_cause || '',
          action_plan: selectedRisk.action_plan || '',
          responsable: selectedRisk.responsable || '',
          risk_control_action_id: selectedRisk.risk_control_action_id || '',
          risk_plan_status_id: selectedRisk.risk_plan_status_id || '',
          risk_strategy_id: selectedRisk.risk_strategy_id || ''
        })
        const impact =
          selectedRisk.risk_impact_area_id === 1
            ? riskTimeImpacts.find(item => item.score === selectedRisk.impact_score)
            : riskCostImpacts.find(item => item.score === selectedRisk.impact_score)
        setSelectedProbability(riskProbabilities.find(item => item.score === selectedRisk.probability_score))
        setSelectedImpact(impact)
      }
      resetForm()
    }
  }, [open])

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description" maxWidth="800px">
      <DialogTitle className={Styles.modal_header}>
        Detalle del Riesgo
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }} className={Styles.modal_body}>
        {/* RIKS DATA */}
        <Grid container columnSpacing={3} sx={{ marginTop: '2rem' }}>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <FormControl className={Styles.input} sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>
                Fase del proyecto <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                sx={{ width: '100% !important' }}
                select
                required
                name="phase_id"
                size="small"
                variant="outlined"
                error={Boolean(touched.phase_id && errors?.phase_id)}
                helperText={touched?.phase_id && errors?.phase_id}
                value={values.phase_id}
                onBlur={handleBlur}
                onChange={handleChange}>
                {phases.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={Styles.input} sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>
                Modalidad <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                select
                required
                sx={{ width: '100% !important' }}
                name="mode"
                size="small"
                variant="outlined"
                error={Boolean(touched.mode && errors?.mode)}
                helperText={touched?.mode && errors?.mode}
                value={values.mode}
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e)
                  handleHasDeadLine(false)
                  if (e.target.value === 'PROBLEM') {
                    handleChangeRiskProbability(6)
                  }
                }}>
                {riskMode.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={1} />
          {/* DIVIDER PHASE AND MODE FROM DATA */}
          <Grid item xs={12}>
            <FormControl className={Styles.input} sx={{ marginTop: '.5rem' }}>
              <Divider />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container columnSpacing={3}>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Tipo de Riesgo <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled={isSystemObserver || isProjectObserver}
                    select
                    required
                    name="type"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.type && errors?.type)}
                    helperText={touched?.type && errors?.type}
                    value={values.type}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {riskType.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Estado <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled={isSystemObserver || isProjectObserver}
                    select
                    required
                    name="risk_status_id"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.risk_status_id && errors?.risk_status_id)}
                    helperText={touched?.risk_status_id && errors?.risk_status_id}
                    value={values.risk_status_id}
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e)
                      if (values.mode === 'RISK' && e.target.value !== 1) {
                        handleHasDeadLine(false)
                      }
                    }}>
                    {riskStatus.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Área de Riesgo <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled={isSystemObserver || isProjectObserver}
                    select
                    required
                    name="risk_area_id"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.risk_area_id && errors?.risk_area_id)}
                    helperText={touched?.risk_area_id && errors?.risk_area_id}
                    value={values.risk_area_id}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {riskAreas.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Urgencia <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled={isSystemObserver || isProjectObserver}
                    select
                    required
                    name="risk_urgency_id"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.risk_urgency_id && errors?.risk_urgency_id)}
                    helperText={touched?.risk_urgency_id && errors?.risk_urgency_id}
                    value={values.risk_urgency_id}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {riskUrgency.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Área de Impacto <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled={isSystemObserver || isProjectObserver}
                    select
                    required
                    name="risk_impact_area_id"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.risk_impact_area_id && errors?.risk_impact_area_id)}
                    helperText={touched?.risk_impact_area_id && errors?.risk_impact_area_id}
                    value={values.risk_impact_area_id}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {impactArea.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Impacto <span className={Styles.asterisk}>*</span>
                  </Box>
                  {riskTimeImpacts && riskCostImpacts && (
                    <TextField
                      disabled={isSystemObserver || isProjectObserver}
                      select
                      required
                      name="impact_score"
                      size="small"
                      variant="outlined"
                      error={Boolean(touched.impact_score && errors?.impact_score)}
                      helperText={touched?.impact_score && errors?.impact_score}
                      value={values.impact_score}
                      onBlur={handleBlur}
                      onChange={e => handleChangeRiskImpact(e.target.value, values.risk_impact_area_id === 1)}>
                      {values.risk_impact_area_id === 1
                        ? riskTimeImpacts.map((option, index) => (
                            <MenuItem key={index} value={option.score}>
                              {option.value} - {option.description}
                            </MenuItem>
                          ))
                        : riskCostImpacts.map((option, index) => (
                            <MenuItem key={index} value={option.score}>
                              {option.value} - {option.description}
                            </MenuItem>
                          ))}
                    </TextField>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Probabilidad <span className={Styles.asterisk}>*</span>
                  </Box>
                  {riskProbabilities && (
                    <TextField
                      disabled={isSystemObserver || isProjectObserver}
                      select
                      required
                      name="probability_score"
                      size="small"
                      variant="outlined"
                      error={Boolean(touched.probability_score && errors?.probability_score)}
                      helperText={touched?.probability_score && errors?.probability_score}
                      value={values.probability_score}
                      onBlur={handleBlur}
                      onChange={e => handleChangeRiskProbability(e.target.value)}>
                      {values.mode === 'PROBLEM' ? (
                        <MenuItem value={riskProbabilities.find(item => item.score === 6).score}>
                          {riskProbabilities.find(item => item.score === 6).value} - 100%
                        </MenuItem>
                      ) : (
                        riskProbabilities.map((option, index) => (
                          <MenuItem key={index} value={option.score}>
                            {option.value} - {option.description}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={Styles.input}>
                  <Box>
                    Severidad <span className={Styles.asterisk}>*</span>
                  </Box>
                  <TextField
                    disabled
                    name="severity_value"
                    size="small"
                    variant="outlined"
                    error={Boolean(touched.severity_value && errors?.severity_value)}
                    helperText={touched?.severity_value && errors?.severity_value}
                    value={values.severity_value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {riskDataLoaded && (
                <>
                  <Box sx={{ transform: 'rotate(-90deg)' }}>Probabilidad</Box>
                  <Box>
                    <TableContainer sx={{ maxWidth: '100%', border: '0.5px solid #ddd' }}>
                      <Table
                        sx={{
                          borderCollapse: 'unset',
                          '& .MuiTableCell-root': {
                            borderRight: '1px solid #eee'
                          }
                        }}>
                        <TableBody>
                          {values.risk_impact_area_id === 1
                            ? probXtime.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell size="small">{item.value}</TableCell>
                                    {item.calculated_items.map((calcItem, i) => {
                                      return (
                                        <TableCell
                                          key={`item-${i}`}
                                          size="small"
                                          sx={{
                                            backgroundColor: calcItem.color,
                                            fontWeight:
                                              item.score === values.probability_score &&
                                              calcItem.value === values.severity_value
                                                ? '900'
                                                : 'regular'
                                          }}>
                                          {calcItem.value}
                                        </TableCell>
                                      )
                                    })}
                                  </TableRow>
                                )
                              })
                            : probXcost.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell size="small">{item.value}</TableCell>
                                    {item.calculated_items.map((calcItem, i) => {
                                      return (
                                        <TableCell
                                          key={`item-${i}`}
                                          size="small"
                                          sx={{
                                            backgroundColor: calcItem.color,
                                            fontWeight:
                                              item.score === values.probability_score &&
                                              calcItem.value === values.severity_value
                                                ? '900'
                                                : 'regular'
                                          }}>
                                          {calcItem.value}
                                        </TableCell>
                                      )
                                    })}
                                  </TableRow>
                                )
                              })}
                          <TableRow>
                            <TableCell size="small" />
                            {values.risk_impact_area_id === 2
                              ? riskCostImpacts.map((impact, i) => {
                                  return (
                                    <TableCell key={`costImpact-${i}`} size="small">
                                      {impact.value}
                                    </TableCell>
                                  )
                                })
                              : riskTimeImpacts.map((impact, i) => {
                                  return (
                                    <TableCell key={`timeImpact-${i}`} size="small">
                                      {impact.value}
                                    </TableCell>
                                  )
                                })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box>{values.risk_impact_area_id === 1 ? 'Impacto en el tiempo' : 'Impacto en costo'}</Box>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          {/* DIVIDER DATA FROM SEVERITY */}
          <Grid item xs={12}>
            <FormControl className={Styles.input} sx={{ marginTop: '.5rem' }}>
              <Divider />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={Styles.input}>
              <Box>
                Título <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                required
                name="title"
                size="small"
                variant="outlined"
                error={Boolean(touched.title && errors?.title)}
                helperText={touched?.title && errors?.title}
                inputProps={{ maxLength: 255 }}
                value={values.title}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small className={touched.title && errors?.title ? Styles.chars_helper_error : undefined}>
                {chars.title}/255
              </small>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl className={Styles.input}>
              <Box>
                Fecha de registro <span className={Styles.asterisk}>*</span>
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={isSystemObserver || isProjectObserver}
                  format="DD/MM/YYYY"
                  value={values.creation_date}
                  onChange={value => setFieldValue('creation_date', value)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: true,
                      error: Boolean(touched.creation_date && errors?.creation_date),
                      helperText: touched?.creation_date && errors?.creation_date,
                      onBlur: handleBlur
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl className={Styles.input}>
              <Box>Fecha de implementación</Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={isSystemObserver || isProjectObserver}
                  format="DD/MM/YYYY"
                  value={values.implementation_date}
                  onChange={value => setFieldValue('implementation_date', value)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: true,
                      error: Boolean(touched.implementation_date && errors?.implementation_date),
                      helperText: touched?.implementation_date && errors?.implementation_date,
                      onBlur: handleBlur
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {values.mode === 'RISK' && values.risk_status_id === 1 && (
            <>
              <Grid item xs={3}>
                <Box>¿Tiene fecha límite?</Box>
                <Checkbox checked={values.has_deadline} onChange={e => handleHasDeadLine(e.target.checked)} />
              </Grid>
              <Grid item xs={3}>
                <FormControl className={Styles.input}>
                  <Box>Fecha límite {values.has_deadline && <span className={Styles.asterisk}>*</span>}</Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!values.has_deadline || isSystemObserver || isProjectObserver}
                      format="DD/MM/YYYY"
                      value={values.deadline_date}
                      minDate={dayjs()}
                      onChange={value => {
                        setFieldValue('deadline_date', value)
                        setFieldValue('deadline_days', value.diff(dayjs().format('MM/DD/YYYY'), 'days'))
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          required: true,
                          error: Boolean(touched.deadline_date && errors?.deadline_date),
                          helperText: touched?.deadline_date && errors?.deadline_date,
                          onBlur: handleBlur
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Box>Días restantes</Box>
                <TextField disabled size="small" variant="outlined" value={values.deadline_days} />
              </Grid>
              <Grid item xs={3}>
                <FormControl className={Styles.input}>
                  <Box>
                    Frecuencia de recordatorio {values.has_deadline && <span className={Styles.asterisk}>*</span>}
                  </Box>
                  <TextField
                    select
                    disabled={!values.has_deadline || isSystemObserver || isProjectObserver}
                    name="reminder_frequency"
                    size="small"
                    variant="outlined"
                    value={values.reminder_frequency}
                    error={Boolean(touched.reminder_frequency && errors?.reminder_frequency)}
                    helperText={touched?.reminder_frequency && errors?.reminder_frequency}
                    onBlur={handleBlur}
                    onChange={handleChange}>
                    {riskReminderFrequency.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControl className={Styles.input}>
              <Box>
                Causa <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                required
                name="description"
                size="small"
                variant="outlined"
                rows={2}
                multiline
                error={Boolean(touched.description && errors?.description)}
                helperText={touched?.description && errors?.description}
                inputProps={{ maxLength: 1000 }}
                value={values.description}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small className={touched.description && errors?.description ? Styles.chars_helper_error : undefined}>
                {chars.description}/1000
              </small>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={Styles.input}>
              <Box>
                Evento <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                name="visible_cause"
                size="small"
                variant="outlined"
                rows={2}
                multiline
                error={Boolean(touched.visible_cause && errors?.visible_cause)}
                helperText={touched?.visible_cause && errors?.visible_cause}
                inputProps={{ maxLength: 1000 }}
                value={values.visible_cause}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small className={touched.visible_cause && errors?.visible_cause ? Styles.chars_helper_error : undefined}>
                {chars.visible_cause}/1000
              </small>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={Styles.input}>
              <Box>
                Consecuencia <span className={Styles.asterisk}>*</span>
              </Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                name="affectation"
                size="small"
                variant="outlined"
                rows={2}
                multiline
                error={Boolean(touched.affectation && errors?.affectation)}
                helperText={touched?.affectation && errors?.affectation}
                inputProps={{ maxLength: 1000 }}
                value={values.affectation}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small className={touched.affectation && errors?.affectation ? Styles.chars_helper_error : undefined}>
                {chars.affectation}/1000
              </small>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={Styles.input}>
              <Box>Responsable</Box>
              <DropdownUsers
                disabled={isSystemObserver || isProjectObserver}
                label=""
                placeholder="Selecciona el usuario responsable"
                projectUsers={currentProject.users}
                value={values.responsable}
                onChange={(e, value) => setFieldValue('responsable', value?.id || null)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={Styles.input}>
              <Box>Estrategia</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                required
                select
                name="risk_strategy_id"
                size="small"
                variant="outlined"
                error={Boolean(touched.risk_strategy_id && errors?.risk_strategy_id)}
                helperText={touched?.risk_strategy_id && errors?.risk_strategy_id}
                value={values.risk_strategy_id}
                onBlur={handleBlur}
                onChange={handleChange}>
                {values.type === 'POSITIVE'
                  ? positiveStrategies.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : negativeStrategies.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={Styles.input}>
              <Box>Acciones de control</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                select
                required
                name="risk_control_action_id"
                size="small"
                variant="outlined"
                error={Boolean(touched.risk_control_action_id && errors?.risk_control_action_id)}
                helperText={touched?.risk_control_action_id && errors?.risk_control_action_id}
                value={values.risk_control_action_id}
                onBlur={handleBlur}
                onChange={handleChange}>
                {controlActions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl className={Styles.input}>
              <Box>Plan de acción</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                name="action_plan"
                size="small"
                variant="outlined"
                rows={5}
                multiline
                error={Boolean(touched.action_plan && errors?.action_plan)}
                helperText={touched?.action_plan && errors?.action_plan}
                inputProps={{ maxLength: 2000 }}
                value={values.action_plan}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small>{chars.action_plan}/2000</small>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl className={Styles.input}>
              <Box>Status del Plan</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                select
                name="risk_plan_status_id"
                size="small"
                variant="outlined"
                error={Boolean(touched.risk_plan_status_id && errors?.risk_plan_status_id)}
                helperText={touched?.risk_plan_status_id && errors?.risk_plan_status_id}
                value={values.risk_plan_status_id}
                onBlur={handleBlur}
                onChange={handleChange}>
                {riskPlanStatus.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={Styles.input}>
              <Box>Seguimiento</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                multiline
                rows={5}
                name="tracking"
                size="small"
                variant="outlined"
                error={Boolean(touched.tracking && errors?.tracking)}
                helperText={touched?.tracking && errors?.tracking}
                inputProps={{ maxLength: 2000 }}
                value={values.tracking}
                onBlur={handleBlur}
                onChange={e => [handleChange(e), handleCountChars(e)]}
              />
              <small>{chars.tracking}/2000</small>
            </FormControl>
          </Grid>
        </Grid>
        {/* TRACKING GRAPH */}
        <Grid container>
          <Grid item xs={12}>
            <LineChart
              xAxis={[
                {
                  data: [...riskHistory.map(item => dayjs(item.created_at))],
                  tickInterval: [...riskHistory.map(item => dayjs(item.created_at))],
                  scaleType: 'time',
                  valueFormatter: value => dayjs(value).format('DD MMM YY'),
                  tickLabelStyle: {
                    angle: -35,
                    textAnchor: 'end'
                  }
                }
              ]}
              yAxis={[
                {
                  tickNumber: 10,
                  min: 0,
                  max: 1,
                  valueFormatter: value => `${value * 100}%`
                }
              ]}
              series={[
                {
                  type: 'line',
                  data: [...riskHistory.map(item => item.severity_value)],
                  area: true,
                  color: 'black',
                  label: 'Severidad'
                }
              ]}
              height={400}
              margin={{ top: 42, bottom: 70, left: 70 }}
              grid={{ horizontal: true, vertial: true }}
              sx={{
                '& .MuiAreaElement-root': {
                  fill: 'url(#line_bar_color)'
                }
                // '& .MuiChartsGrid-line': { strokeDasharray: '5 3', strokeWidth: 2 }
              }}>
              <ColorPalette />
              <rect x={0} y={0} width={5} height="90%" fill="url(#line_bar_color)" />
            </LineChart>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={Styles.modal_footer}>
        {!isSystemObserver && !isProjectObserver && (
          <>
            <Button sx={{ width: '10rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
              Cancelar
            </Button>
            <Button sx={{ width: '10rem' }} disabled={loading} variant="contained" onClick={handleSubmit}>
              {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Guardar Riesgo'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

ModalRisk.propTypes = {
  actionType: PropTypes.string,
  projectId: PropTypes.string,
  phases: PropTypes.array,
  selectedRisk: PropTypes.object,
  riskProbabilities: PropTypes.array,
  riskCostImpacts: PropTypes.array,
  riskTimeImpacts: PropTypes.array,
  riskSeverityLimits: PropTypes.object,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModalRisk
