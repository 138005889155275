import axios from '../axios'

class RiskService {
  static async store(params) {
    const resp = await axios.post('/risks', params)
    return resp.data
  }

  static async find(id) {
    const resp = await axios.get(`/risks/${id}`)
    return resp.data
  }

  static async findRiskHistory(id) {
    const resp = await axios.get(`/risks/${id}/history`)
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/risks/${id}`, { ...params })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/risks/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/risks/${id}/restore`)
    return resp.data
  }
}

export default RiskService
