import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Collapse, Divider } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Styles from './_CollapseDropdown.module.scss'

const CollapseDropdown = ({ children, header, title, openFirst }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    openFirst && setOpen(true)
  }, [])
  return (
    <Box className={Styles.collapse_container}>
      <Box role="button" className={Styles.collapse_header} onClick={() => setOpen(!open)}>
        {header || title || 'Collapse Dropdown'}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
      <Divider />
      <Collapse in={open}>
        <Box className={Styles.collapse_body}>{children}</Box>
      </Collapse>
    </Box>
  )
}

CollapseDropdown.propTypes = {
  children: PropTypes.element,
  header: PropTypes.element,
  openFirst: PropTypes.bool,
  title: PropTypes.string
}

export default CollapseDropdown
