import axios from 'axios'

const intance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    Accept: 'application/json'
  }
})

intance.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token')
    if (token) {
      request.headers = { ...request.headers, Authorization: `Bearer ${token}` }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

// Clear storage and refresh page when token expired (HTTP code 401). Router will redirect back to the login
intance.interceptors.response.use(
  response => response,
  error => {
    if (
      (error.response?.status === 401 && error.response.data.message.includes('Unauthenticated')) ||
      error.code === 'ERR_NETWORK'
    ) {
      localStorage.clear()
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

export default intance
