export const customStylesDatePicker = {
  width: '100%',
  '& .MuiInputLabel-asterisk': {
    color: 'red'
  },
  '& .Mui-error': {
    color: 'red !importatnt',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }
  }
}

export const riskColors = {
  red: '#ff2121ab',
  yellow: '#ffff009e',
  green: '#05de215c'
}
