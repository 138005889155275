import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ marginTop: '-3rem', maxWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src="img/Logo_white_bg.png" alt="" width="100%" />
        <Box sx={{ fontSize: '3rem', fontWeight: '900' }}>404</Box>
        <Box sx={{ fontSize: '1.5rem', marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
          Oops! Parece que la página que buscas no existe
        </Box>
        <Button variant="outlined" onClick={() => navigate('/')}>
          Regresar
        </Button>
      </Box>
    </Box>
  )
}

export default NotFound
