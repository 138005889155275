import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import UserService from 'api/services/user.service'

const DropdownUsers = ({
  error,
  label = 'Seleccionar usuarios',
  disabled,
  helperText,
  placeholder,
  projectUsers,
  onBlur,
  onChange,
  value
}) => {
  const [usersList, setUsersList] = useState([])

  const getUsers = async () => {
    try {
      const resp = await UserService.findAllWithProjectRoles()
      setUsersList(resp)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!projectUsers) {
      getUsers()
    } else {
      setUsersList(projectUsers)
    }
  }, [])

  return (
    <Autocomplete
      disabled={disabled}
      sx={{ width: '100%' }}
      options={usersList}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value}
      getOptionLabel={option => {
        let fullName = ''
        if (option.name) fullName = `${option.name} ${option.last_name}`
        else {
          const user = usersList.find(item => item.id === option)
          if (user) {
            fullName = `${user.name} ${user.last_name}`
          }
        }
        return fullName
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label={label}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
        />
      )}
    />
  )
}

DropdownUsers.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  projectUsers: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any
}

export default DropdownUsers
