import { createSlice } from '@reduxjs/toolkit'

export const projectManagementSlice = createSlice({
  name: 'projectManagement',
  initialState: {
    project: {},
    project_storage: {
      id: '',
      project_role: ''
    }
  },
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload
    },
    setProjectPhases: (state, action) => {
      state.project.phases = action.payload
    },
    setProjectStorage: (state, action) => {
      state.project_storage = action.payload
    },
    setProjectStorageRole: (state, action) => {
      state.project_storage.project_role = action.payload
    },
    setProjectStorageId: (state, action) => {
      state.project_storage.id = action.payload
    }
  }
})

export const { setProject, setProjectPhases, setProjectStorage, setProjectStorageRole, setProjectStorageId } =
  projectManagementSlice.actions

export default projectManagementSlice.reducer
