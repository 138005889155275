import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Alert, Box, Breadcrumbs, IconButton, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import TimelineIcon from '@mui/icons-material/Timeline'
import ProjectService from 'api/services/project.service'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import { ManageAccountsOutlined } from '@mui/icons-material'
import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import { useDate } from 'hooks/useDate'
import { useProjectRoles } from 'hooks/useProjectRoles'
import DropdownProjectRole from 'views/Projects/components/DropdownProjectRole/DropdownProjectRole'
import SideModalProject from 'views/Projects/components/SideModalProject/SideModalProject'
import SideModalManageUsers from 'views/Projects/components/SideModalManageUsers/SideModalManageUsers'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import { setProject } from '../../../../reduxStore/reducer/projectManagementSlice'
import Styles from './_ProjectLayout.module.scss'

const ProjectLayout = () => {
  const { setAlert } = useAlert()
  const { formatDate } = useDate()
  const { projectId } = useParams()
  const { project } = useSelector(state => state.projectManagement)
  const dispatch = useDispatch()
  const { pathname: url } = useLocation()
  const navigate = useNavigate()
  const authUser = useSelector(store => store.userManagement.user)
  const { isSystemObserver } = useAuth()
  const { isProjectObserver } = useProjectRoles()

  const [today, setToday] = useState({ date: dayjs(), week: '' })
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState()
  const [sidemodalProject, setSidemodalProject] = useState({ show: false, actionType: '' })
  const [showSidemodalManageUsers, setShowSideModalManageUsers] = useState(false)
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })

  const [tabHeader, setTabHeader] = useState('')

  const getProjectDetail = async () => {
    setLoading(true)
    try {
      const resp = await ProjectService.find(projectId)
      if (authUser) {
        const userFounded = resp.users.find(userElement => userElement.id === authUser.id)
        const currentProject = localStorage.getItem(`project_${projectId}`)
        if (
          !(
            currentProject &&
            JSON.parse(currentProject).id === resp.id &&
            userFounded.project_roles.find(item => item.id === JSON.parse(currentProject).project_role)
          )
        ) {
          localStorage.setItem(
            `project_${projectId}`,
            JSON.stringify({ id: resp.id, project_role: userFounded.project_roles[0].id })
          )
        }
        setCurrentUser(userFounded)
      }
      setToday(prevSate => ({ ...prevSate, week: today.date.diff(dayjs(resp.start_date), 'weeks') + 1 }))
      dispatch(setProject(resp))
      setLoading(false)
    } catch (error) {
      if (error.response.status === 401) {
        setAlert(true, 'error', 'No estás autorizado para ver este proyecto, redirigiendo...')
        setTimeout(() => {
          navigate('/projects')
        }, 1500)
      } else {
        console.error(error)
      }
    }
  }

  const handleTabHeadersChange = value => {
    navigate(value)
  }

  const handleEditProject = () => {
    setSidemodalProject({ show: true, actionType: 'update' })
  }

  const handleSaveProject = result => {
    setSidemodalProject(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      setAlert(true, 'success', 'Proyecto guardado con éxito')
      getProjectDetail()
    } else {
      getProjectDetail()
      setAlert(true, 'error', 'Ocurrió un error al guardar la Fase, intenta de nuevo más tarde')
    }
  }

  const handleConfirmDeleteProject = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await ProjectService.delete(project.id)
      setLoading(true)
      setModalDelete({ show: false, loading: false })
      setAlert(true, 'success', 'Proyecto eliminado con éxito, redirigiendo a mis Proyectos...')
      setTimeout(() => {
        navigate(`/projects`)
      }, 2000)
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert(true, 'error', 'Ocurrió un error al eliminar el Proyecto, intenta de nuevo más tarde')
    }
  }

  const handleUpdateUsers = result => {
    setShowSideModalManageUsers(false)
    if (result === 'success') {
      setAlert(true, 'success', 'Usuarios actualizados con éxito, Recargando')
      getProjectDetail()
    } else {
      getProjectDetail()
      setAlert(true, 'error', 'Ocurrió un error al actualizar los usuarios, intenta de nuevo más tarde')
    }
  }

  useEffect(() => {
    if (authUser) {
      getProjectDetail()
    }
  }, [authUser])

  useEffect(() => {
    /** Comentado porque de momento no se usará SubProyectos */
    // if (url.includes('subprojects')) setTabHeader('subprojects')
    if (url.includes('risks')) setTabHeader('risks')
    else if (url.includes('phases')) setTabHeader('phases')
    else setTabHeader('')
  }, [url])

  return (
    <Box>
      {!loading && Object.keys(project).length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '.25rem'
            }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Box className={Styles.breadcrumb__item} onClick={() => navigate(`/projects`)}>
                Mis Proyectos
              </Box>
              {project.parent_projects.reverse().map((parent, index) => {
                return (
                  <Box
                    key={index}
                    className={Styles.breadcrumb__item}
                    onClick={() => navigate(`/projects/${parent.id}`)}>
                    {parent.name}
                  </Box>
                )
              })}
              <Typography key="3" color="text.primary">
                <b>{project.name}</b>
              </Typography>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ fontWeight: 'bold', marginRight: '.5rem' }}>Rol de proyecto: </Box>
              <DropdownProjectRole user={currentUser} />
            </Box>
          </Box>
          <Box
            sx={{
              padding: '1rem',
              borderRadius: '8px',
              backgroundColor: 'white',
              boxShadow: '0px 0px 15px rgba(21,21,21,.12)'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                className={`${Styles.tab} ${tabHeader === '' && Styles.tab_active}`}
                onClick={() => handleTabHeadersChange('')}>
                <InsertChartOutlinedIcon />
                <span>Proyecto</span>
              </Box>
              <Box
                className={`${Styles.tab} ${tabHeader === 'phases' && Styles.tab_active}`}
                onClick={() => handleTabHeadersChange('phases')}>
                <TimelineIcon />
                <span>Fases</span>
              </Box>
              {/* <Box
                className={`${Styles.tab} ${tabHeader === 'subprojects' && Styles.tab_active}`}
                onClick={() => handleTabHeadersChange('subprojects')}>
                <ListOutlinedIcon />
                <span>SubProyectos</span>
              </Box> */}
              <Box
                className={`${Styles.tab} ${tabHeader === 'risks' && Styles.tab_active}`}
                onClick={() => handleTabHeadersChange('risks')}>
                <ReportOutlinedIcon />
                <span>Riesgos</span>
              </Box>
              <Box sx={{ width: '100%' }} />
            </Box>
            <Box
              sx={{
                padding: '1rem',
                border: '1px solid #ddd',
                borderRadius: '4px',
                borderTopLeftRadius: tabHeader === '' ? '0px' : '4px'
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <h2>
                      <b>{project.name}</b>
                    </h2>
                  </Box>
                  {!isSystemObserver && !isProjectObserver && (
                    <Box sx={{ marginLeft: '1rem' }}>
                      <LightTooltip title="Editar proyecto" arrow>
                        <IconButton onClick={handleEditProject}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Gestionar usuarios" arrow>
                        <IconButton onClick={() => setShowSideModalManageUsers(true)}>
                          <ManageAccountsOutlined />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Eliminar proyecto" arrow>
                        <IconButton onClick={() => setModalDelete({ show: true, loading: false })}>
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </LightTooltip>
                    </Box>
                  )}
                </Box>
                <small>{project.short_description}</small>
                <Box sx={{ marginTop: '.5rem' }}>
                  <b>Fecha del día:</b> <span>{formatDate(today.date)}</span>
                  {(project.end_date_updated && today.date.isBefore(dayjs(project.end_date_updated))) ||
                  today.date.isBefore(dayjs(project.end_contractual_date)) ? (
                    <>
                      <b style={{ marginLeft: '2rem' }}>Semana:</b> <span>{today.week}</span> <br />
                    </>
                  ) : (
                    <Alert severity="info">El día de hoy se encuentra fuera del plazo del proyecto</Alert>
                  )}
                </Box>
              </Box>
              <SideModalProject
                actionType={sidemodalProject.actionType}
                isSubProject={sidemodalProject.actionType === 'create'}
                open={sidemodalProject.show}
                selectedProject={project}
                onCancel={() => setSidemodalProject(false)}
                onConfirm={handleSaveProject}
              />

              <SideModalManageUsers
                open={showSidemodalManageUsers}
                selectedUsers={project.users}
                projectId={project.id}
                onCancel={() => setShowSideModalManageUsers(false)}
                onConfirm={handleUpdateUsers}
              />
              <ModalDelete
                entity="Proyecto"
                element={project}
                open={modalDelete.show}
                onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
                onConfirm={handleConfirmDeleteProject}
                loading={modalDelete.loading}
              />
              <Outlet context={{ project }} />
            </Box>
          </Box>
        </>
      ) : (
        <SkeletonLoading type="project" />
      )}
    </Box>
  )
}

export default ProjectLayout
