import axios from '../axios'

class UserService {
  static async store(params) {
    const resp = await axios.post('/users', params)
    return resp.data
  }

  static async storePublic(params) {
    const resp = await axios.post('/users/public', params)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/users')
    return resp.data
  }

  static async findAllWithProjectRoles() {
    const resp = await axios.get('/users/management')
    return resp.data
  }

  static async findProjects() {
    const resp = await axios.get('/users/projects')
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/users/${id}`, { ...params })
    return resp.data
  }

  static async updatePassword(id, params) {
    const resp = await axios.put(`/users/${id}/update_password`, { ...params })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/users/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/users/${id}/restore`)
    return resp.data
  }
}

export default UserService
