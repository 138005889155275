export const userRoles = [
  { value: 1, label: 'DEVELOPER' },
  { value: 2, label: 'ADMIN' },
  { value: 3, label: 'USER' },
  { value: 4, label: 'USER_OBSERVER' }
]

export const ROLE_DEVELOPER = 1
export const ROLE_ADMIN = 2
export const ROLE_USER = 3
export const ROLE_USER_OBSERVER = 4

export const projectRoles = [
  { value: 1, label: 'PROJECT_MANAGER' },
  { value: 2, label: 'RISK_ANALIST' },
  { value: 3, label: 'PLANNING_ANALIST' },
  { value: 4, label: 'PROJECT_OBSERVER' }
]

export const riskAreas = [
  { value: 1, label: 'Financieros' },
  { value: 2, label: 'Procura' },
  { value: 3, label: 'Contractuales' },
  { value: 4, label: 'Diseño' },
  { value: 5, label: 'Construcción' },
  { value: 6, label: 'Sociales/Políticos' },
  { value: 7, label: 'Regulatorios' },
  { value: 8, label: 'Administración/Gestión' },
  { value: 9, label: 'Fuerza Mayor' }
]

export const riskType = [
  { value: 'POSITIVE', label: 'Positivo' },
  { value: 'NEGATIVE', label: 'Negativo' }
]

export const riskMode = [
  { value: 'RISK', label: 'Riesgo' },
  { value: 'PROBLEM', label: 'Problema' }
]

export const impactArea = [
  { value: 1, label: 'Plazo' },
  { value: 2, label: 'Costo' }
]

export const riskStatus = [
  { value: 1, label: 'Activo' },
  { value: 2, label: 'Inactivo' },
  { value: 3, label: 'Descartado' }
]

export const riskPlanStatus = [
  { value: 1, label: 'En definición' },
  { value: 2, label: 'En revisión' },
  { value: 3, label: 'Aprobado/En implementación' },
  { value: 4, label: 'Descartado' },
  { value: 5, label: 'Implementado' },
  { value: 6, label: 'No aplica' }
]

export const positiveStrategies = [
  { value: 6, label: 'Escalar' },
  { value: 7, label: 'Explotar' },
  { value: 8, label: 'Compartir' },
  { value: 9, label: 'Mejorar' },
  { value: 10, label: 'Aceptar' }
]

export const negativeStrategies = [
  { value: 1, label: 'Escalar' },
  { value: 2, label: 'Evitar' },
  { value: 3, label: 'Transferir' },
  { value: 4, label: 'Mitigar' },
  { value: 5, label: 'Aceptar' }
]

export const controlActions = [
  { value: 1, label: 'Adquirir seguro' },
  { value: 2, label: 'Escalar el riesgo' },
  { value: 3, label: 'Transferir al subcontratista' },
  { value: 4, label: 'Transferir al proveedor' },
  { value: 5, label: 'Desarrollar un programa y controlar' },
  { value: 6, label: 'Programa de gestión de la calidad' },
  { value: 7, label: 'Gestión de materiales/equipos críticos' },
  { value: 8, label: 'Plan de emergencia' },
  { value: 9, label: 'Contratación selectiva de personal' },
  { value: 10, label: 'Plan de entrenamiento/ Entrenamiento' },
  { value: 11, label: 'Cambiar el sitio de trabajo' },
  { value: 12, label: 'Ofertas competitivas' },
  { value: 13, label: 'Compras tempranas' },
  { value: 14, label: 'Fondo de contingencia' },
  { value: 15, label: 'Plan de contingencia' },
  { value: 16, label: 'Aceptar el riesgo' },
  { value: 17, label: 'Transferir al Contratista' },
  { value: 18, label: 'Otros' }
]

export const riskUrgency = [
  { value: 1, label: 'Baja' },
  { value: 2, label: 'Media' },
  { value: 3, label: 'Alta' }
]

export const severityLimits = {
  low: 0.07,
  medium: 0.24,
  high: 1.0
}

export const riskReminderFrequency = [
  { value: 1, label: 'Diario' },
  { value: 2, label: 'Cada 2 días' },
  { value: 3, label: 'Cada 3 días' },
  { value: 4, label: 'Cada 4 días' },
  { value: 5, label: 'Cada 5 días' },
  { value: 6, label: 'Cada 6 días' },
  { value: 7, label: 'Cada 7 días' },
  { value: 8, label: 'Cada 8 días' },
  { value: 9, label: 'Cada 9 días' },
  { value: 10, label: 'Cada 10 días' },
  { value: 11, label: 'Cada 11 días' },
  { value: 12, label: 'Cada 12 días' },
  { value: 13, label: 'Cada 13 días' },
  { value: 14, label: 'Cada 14 días' },
  { value: 15, label: 'Cada 15 días' },
  { value: 16, label: 'Cada 16 días' },
  { value: 17, label: 'Cada 17 días' },
  { value: 18, label: 'Cada 18 días' },
  { value: 19, label: 'Cada 19 días' },
  { value: 20, label: 'Cada 20 días' },
  { value: 21, label: 'Cada 21 días' },
  { value: 22, label: 'Cada 22 días' },
  { value: 23, label: 'Cada 23 días' },
  { value: 24, label: 'Cada 24 días' },
  { value: 25, label: 'Cada 25 días' },
  { value: 26, label: 'Cada 26 días' },
  { value: 27, label: 'Cada 27 días' },
  { value: 28, label: 'Cada 28 días' },
  { value: 29, label: 'Cada 29 días' },
  { value: 30, label: 'Cada 30 días' }
]

export const SPI_COLORS = {
  blue: '#00a4e5',
  green: 'green',
  orange: 'orange',
  red: '#ce1010'
}
