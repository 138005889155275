import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const ModalDelete = ({ element, entity, open, loading, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
      <DialogTitle>Estás seguro que deseas eliminar:</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <h2 style={{ textAlign: 'center', fontWeight: 400 }}>
          {entity}: <b>{element?.name || element?.title}</b>
        </h2>
        <br />
        <span style={{ textAlign: 'center' }}>
          No podrás revertir esta acción <br />y se borrará toda la información relacionada
        </span>
      </DialogContent>
      <DialogActions sx={{ padding: '1.5rem', justifyContent: 'center' }}>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
          Cancelar
        </Button>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="contained" onClick={() => onConfirm()}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Eliminar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalDelete.propTypes = {
  loading: PropTypes.bool,
  element: PropTypes.object,
  entity: PropTypes.string,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModalDelete
