import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import {
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer
} from '@mui/x-charts'
import RemoveIcon from '@mui/icons-material/Remove'
import { EditOutlined } from '@mui/icons-material'
import ProjectService from 'api/services/project.service'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import { useAuth } from 'hooks/useAuth'
import { useCurrency } from 'hooks/useCurrency'
import { useInputValidations } from 'hooks/useInputValidations'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { setProject } from '../../../../reduxStore/reducer/projectManagementSlice'
import Styles from './_PhaseDataCosts.module.scss'

const PhaseDataCosts = ({
  cutWeek,
  estimatedPV,
  finalProjectedDate,
  hasUpdatedEndDate,
  hasProjectedData,
  isTableEdited,
  hitoPMOProjectionLPO,
  hitoPMOProjectionTTT,
  lastEstimatedValue,
  loadingData,
  phase,
  projectedCostValues,
  projectedWeeklyEVCostValues,
  realAC,
  today,
  totalWeeksWithProjection,
  weekHeaders,
  weeklyCpi,
  weeklyEV,
  handleCancelEditTable,
  handleChangeRowValue,
  handleChangeCostsRowValue,
  handleEditTable,
  handleSaveTableData,
  setWeekHeaders
}) => {
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  const dispatch = useDispatch()
  const { formatStringCurrency } = useCurrency()
  const { onKeyPressValidateDecimalNumber } = useInputValidations()
  const { project } = useOutletContext()
  const { isSystemObserver } = useAuth()
  const { isProjectObserver } = useProjectRoles()
  const weeksSize = weekHeaders.length

  const [enableEdit, setEnableEdit] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const leftHeaders = [
    {
      key: 'estimated_pv',
      label: 'PV Programado'
    },
    {
      key: 'weekly_ev',
      label: 'EV (Valor ganado)'
    },
    {
      key: 'real_ac',
      label: 'Costo Real AC'
    },
    {
      key: 'weekly_cpi',
      label: 'CPI (EV / AC)'
    }
  ]

  const getDataType = {
    estimated_pv: estimatedPV,
    weekly_ev: weeklyEV,
    real_ac: realAC,
    weekly_cpi: weeklyCpi
  }

  const calculateWeekHeaders = week => {
    let classes = ''
    if (
      dayjs(phase.start_date).isSame(week.start_date) ||
      dayjs(phase.start_date).isBetween(week.start_date, week.end_date)
    ) {
      classes += `${Styles.start_date} `
    } else if (
      dayjs(phase.end_date).isSame(week.start_date) ||
      dayjs(phase.end_date).isBetween(week.start_date, week.end_date)
    ) {
      classes += `${Styles.end_date} `
    } else if (week.number === today.week) {
      classes += `${Styles.today} `
    }

    return classes
  }

  const handleAddWeek = async () => {
    if (lastEstimatedValue !== 100) return
    try {
      const lastWeek = weekHeaders[weekHeaders.length - 1]
      await ProjectService.addWeek(project.id, {
        end_date_updated: hasUpdatedEndDate
          ? dayjs(project.end_date_updated).add(1, 'week')
          : dayjs(project.end_contractual_date).add(1, 'week'),
        week_number: lastWeek.number + 1
      })
      dispatch(
        setProject({
          ...project,
          end_date_updated: hasUpdatedEndDate
            ? dayjs(project.end_date_updated).add(1, 'week').format('YYYY-MM-DD')
            : dayjs(project.end_contractual_date).add(1, 'week').format('YYYY-MM-DD')
        })
      )
      const weeks = [...weekHeaders]
      weeks.push({
        label: `Sem ${lastWeek.number + 1}`,
        key: `week_${lastWeek.number + 1}`,
        number: lastWeek.number + 1,
        start_date: lastWeek.start_date.add(1, 'week'),
        cutoff_date: lastWeek.cutoff_date.add(1, 'week'),
        end_date: lastWeek.end_date.add(1, 'week')
      })
      setWeekHeaders(weeks)
      handleChangeRowValue('100.00', 'estimated_percentage', `week_${lastWeek.number + 1}`)
    } catch (error) {
      console.error(error)
    }
  }

  const handelRemoveWeek = async () => {
    try {
      const lastWeek = weekHeaders[weekHeaders.length - 1]
      await ProjectService.deleteWeek(project.id, {
        end_date_updated: dayjs(project.end_date_updated).subtract(1, 'week'),
        week_number: lastWeek.number
      })
      dispatch(
        setProject({
          ...project,
          end_date_updated: dayjs(project.end_date_updated).subtract(1, 'week').format('YYYY-MM-DD')
        })
      )
      const weeks = [...weekHeaders]
      weeks.pop()
      setWeekHeaders(weeks)
    } catch (error) {
      console.error(error)
    }
  }

  const renderCellEdit = (key, week) => {
    const keys = {
      estimated_pv: formatStringCurrency(estimatedPV[week.key], true),
      weekly_ev: formatStringCurrency(weeklyEV[week.key], true),
      real_ac:
        week.number > cutWeek.number ? (
          <LightTooltip title="Asegurate de registrar primero el avance real en la tabla de avance" arrow>
            <span>
              <TextField
                size="small"
                value={realAC[week.key]}
                disabled
                InputProps={{
                  inputProps: {
                    sx: { minWidth: '6rem !important', padding: '3px 10px 3px 5px' }
                  },
                  startAdornment: <span>$&nbsp;</span>
                }}
              />
            </span>
          </LightTooltip>
        ) : (
          <TextField
            size="small"
            value={realAC[week.key]}
            disabled={week.number > cutWeek.number}
            InputProps={{
              inputProps: {
                sx: { minWidth: '6rem !important', padding: '3px 10px 3px 5px' }
              },
              startAdornment: <span>$&nbsp;</span>
            }}
            // error={}
            onBlur={e => handleChangeCostsRowValue(formatStringCurrency(e.target.value.replaceAll(',', '')), week.key)}
            onChange={e => handleChangeCostsRowValue(e.target.value, week.key)}
            onKeyDown={onKeyPressValidateDecimalNumber}
          />
        ),
      weekly_cpi: weeklyCpi[week.key]
    }

    return keys[key]
  }

  useEffect(() => {
    setEnableEdit(!isSystemObserver && !isProjectObserver)
  }, [isSystemObserver, isProjectObserver])

  useEffect(() => {
    if (isTableEdited) {
      const keysList = Object.keys(realAC)
      const firstEmptyIndex = keysList.findIndex(key => realAC[key] === '')
      if (firstEmptyIndex !== -1) {
        keysList.reverse()
        const firstNotEmpty = keysList.findIndex(key => realAC[key] !== '')
        setDisableButton(weeksSize - firstNotEmpty > firstEmptyIndex)
      }
    }
  }, [realAC])

  return (
    <>
      <Box sx={{ marginBottom: '2rem' }}>
        {weekHeaders.length > 0 && hasProjectedData && (
          <ResponsiveChartContainer
            sx={{
              '& .MuiChartsAxis-directionX tspan': {
                fontSize: '.8rem !important'
              },
              '& .MuiLineElement-root': {
                strokeWidth: 2.5
              },
              marginLeft: '-1rem'
            }}
            xAxis={[
              {
                tickNumber: 20,
                min: dayjs(project.start_date).subtract(1, 'week'),
                max:
                  finalProjectedDate && totalWeeksWithProjection.length > weekHeaders.length
                    ? dayjs(finalProjectedDate).add(1, 'week')
                    : project.end_date_updated
                      ? dayjs(project.end_date_updated).add(1, 'week')
                      : dayjs(project.end_contractual_date).add(1, 'week'),
                data:
                  finalProjectedDate && totalWeeksWithProjection.length > weekHeaders.length
                    ? totalWeeksWithProjection
                    : weekHeaders.map(week => dayjs(week.cutoff_date)),
                scaleType: 'time',
                valueFormatter: value => dayjs(value).format('DD MMM YY'),
                tickLabelStyle: {
                  angle: -35,
                  textAnchor: 'end'
                }
              }
            ]}
            yAxis={[
              {
                // tickNumber: 10,
                // min: 0,
                // max: 100,
                valueFormatter: value => `-  ${formatStringCurrency(value, true)}`,
                tickLabelStyle: {
                  textAnchor: 'start',
                  paddingLeft: '.5rem',
                  fontSize: '14px'
                }
              }
            ]}
            height={450}
            series={[
              {
                type: 'line',
                data: weekHeaders.map(week => estimatedPV[week.key]).filter(item => item !== ''),
                area: true,
                showMark: false,
                color: '#9c17dd',
                label: 'PV Programado',
                valueFormatter: value => (value ? `${formatStringCurrency(value, true)}` : '')
              },
              {
                type: 'line',
                data: weekHeaders.map(week => weeklyEV[week.key]).filter(item => item !== ''),
                area: true,
                showMark: false,
                color: '#082588',
                label: 'EV Valor Ganado',
                valueFormatter: value => (value ? `${formatStringCurrency(value, true)}` : '')
              },
              {
                type: 'line',
                data: projectedWeeklyEVCostValues,
                area: true,
                showMark: false,
                color: '#cccccc',
                label: 'EV Proyectado',
                valueFormatter: value => (value ? `${formatStringCurrency(value, true)}` : '')
              },
              {
                type: 'line',
                data: weekHeaders
                  .map(week => (realAC[week.key] ? Number(realAC[week.key].replaceAll(',', '')) : ''))
                  .filter(item => item !== ''),
                area: true,
                showMark: false,
                color: '#ffb103',
                label: 'Costo Real AC',
                valueFormatter: value => (value ? `${formatStringCurrency(value, true)} CPI: ` : '')
              },
              {
                type: 'line',
                data: projectedCostValues,
                area: true,
                showMark: false,
                color: '#11cc67',
                label: 'AC Proyectado',
                valueFormatter: value => (value ? `${formatStringCurrency(value, true)}` : '')
              }
            ]}>
            <LinePlot />
            <ChartsXAxis />
            <ChartsYAxis />
            <ChartsLegend />
            <MarkPlot />

            {/* Inician las línes de refrencia de los HITOs */}
            {finalProjectedDate && (
              <ChartsReferenceLine
                x={hitoPMOProjectionTTT.date}
                lineStyle={{ strokeDasharray: '10 5', stroke: '#66bb6a', strokeWidth: 2, shapeRendering: 'auto' }}
                labelStyle={{ fontSize: '10', stroke: '#66bb6a' }}
                label={`${hitoPMOProjectionTTT.date.format('DD/MMM/YY')}\n${project.ttt_dates_label}\n(TEAC)`}
                labelAlign="start"
              />
            )}
            <ChartsReferenceLine
              x={new Date(project.end_contractual_date)}
              lineStyle={{ strokeDasharray: '10 5', stroke: '#9c27b0', strokeWidth: 2, shapeRendering: 'auto' }}
              labelStyle={{ fontSize: '10', stroke: '#9c27b0' }}
              label={`${dayjs(project.end_contractual_date).format('DD/MMM/YY')}\n${project.ttt_dates_label}`}
              labelAlign="end"
            />
            {finalProjectedDate && project.show_lpo_dates && hitoPMOProjectionLPO.date && (
              <ChartsReferenceLine
                x={hitoPMOProjectionLPO.date}
                lineStyle={{ strokeDasharray: '10 5', stroke: '#00bbfd', strokeWidth: 2, shapeRendering: 'auto' }}
                labelStyle={{ fontSize: '10', stroke: '#00bbfd' }}
                label={`${hitoPMOProjectionLPO.date.format('DD/MMM/YY')}\n${project.lpo_dates_label}\n(TEAC)`}
                labelAlign="middle"
              />
            )}
            {project.show_lpo_dates && project.lpo_contractual_date && (
              <ChartsReferenceLine
                x={new Date(project.lpo_contractual_date)}
                lineStyle={{ strokeDasharray: '10 5', stroke: '#082588', strokeWidth: 2, shapeRendering: 'auto' }}
                labelStyle={{ fontSize: '10', stroke: '#082588' }}
                label={`${dayjs(project.lpo_contractual_date).format('DD/MMM/YY')}\n${project.lpo_dates_label}`}
                labelAlign="start"
              />
            )}
            {/* Terminan las línes de refrencia de los HITOs */}

            <LineHighlightPlot />
            <ChartsAxisHighlight x="line" />
            {/* Pendiente, posiblemente se pueda mejorar */}
            <ChartsTooltip trigger="axis" />
          </ResponsiveChartContainer>
        )}
      </Box>
      {enableEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
            padding: '1rem 0',
            borderBottom: '1px solid #ddd',
            borderTop: '1px solid #ddd'
          }}>
          <h3>Tabla de datos por semana</h3>
          {isTableEdited ? (
            <Box>
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: '1rem' }}
                disabled={loadingData}
                onClick={handleCancelEditTable}>
                Cancelar
              </Button>
              <LightTooltip
                title="Asegúrate de no dejar semanas vacías anteriores a la fecha de corte"
                arrow
                placement="top">
                <span>
                  <Button variant="contained" disabled={loadingData || disableButton} onClick={handleSaveTableData}>
                    Guardar Datos
                  </Button>
                </span>
              </LightTooltip>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<EditOutlined />} onClick={handleEditTable}>
              Editar Datos
            </Button>
          )}
        </Box>
      )}
      <Box sx={{ position: 'relative' }}>
        {loadingData && (
          <Box className={Styles.overlay}>
            <CircularProgress size="2rem" sx={{ marginRight: '1rem' }} />
            Guardando datos . . .
          </Box>
        )}
        <TableContainer sx={{ maxWidth: '100%', border: '0.5px solid #ddd', position: 'relative' }}>
          <Table
            sx={{
              borderCollapse: 'unset',
              '& .MuiTableCell-root': {
                borderRight: '1px solid #eee'
              }
            }}>
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid #eee', backgroundColor: 'blue' }}>
                <TableCell
                  className={Styles.column_sticky_left}
                  sx={{ borderBottom: '2px solid #bebaba', fontWeight: '700' }}>
                  Datos
                </TableCell>
                {weekHeaders.map(week => {
                  return (
                    <TableCell
                      key={week.number}
                      className={`
                                ${Styles.column} ${Styles.column_header} ${calculateWeekHeaders(week)} ${dayjs(week.start_date).isBefore(project.end_contractual_date) ? '' : Styles.week_added}`}
                      sx={{
                        borderBottom: '2px solid #bebaba'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          lineHeight: 'normal'
                        }}>
                        {phase.name === 'General' &&
                          dayjs(week.start_date).isAfter(project.end_contractual_date) &&
                          week.number === weekHeaders.length && (
                            <LightTooltip
                              title="Al eliminar la semana, se eliminarán todos los datos relacionados en todas las fases"
                              arrow
                              placement="top">
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                sx={{ minWidth: '1.2rem', height: '1.2rem', padding: '0', marginBottom: '.2rem' }}
                                onClick={handelRemoveWeek}>
                                <RemoveIcon sx={{ color: 'white' }} fontSize="small" />
                              </Button>
                            </LightTooltip>
                          )}
                        <Box sx={{ fontWeight: '600' }}>{week.label}</Box>
                        <small>{dayjs(week.cutoff_date).format('DD/MM/YYYY')}</small>
                      </Box>
                    </TableCell>
                  )
                })}
                {phase.name === 'General' && (
                  <TableCell
                    className={`${Styles.column} ${Styles.column_header} ${Styles.week_added}`}
                    sx={{
                      borderBottom: '2px solid #bebaba'
                    }}>
                    <LightTooltip title="Asegúrate de tener al 100% el avance programado" arrow placement="top">
                      <span>
                        <Button
                          disabled={lastEstimatedValue !== 100}
                          variant="contained"
                          sx={{ whiteSpace: 'normal', lineHeight: 'normal' }}
                          onClick={handleAddWeek}>
                          Agregar semana
                        </Button>
                      </span>
                    </LightTooltip>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {leftHeaders.map((header, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      size="small"
                      className={Styles.column_sticky_left}
                      sx={{ borderBottom: '1px solid #c3c3c3' }}>
                      {header.label}
                    </TableCell>
                    {enableEdit
                      ? weekHeaders.map(week => {
                          return (
                            <TableCell
                              size="small"
                              key={week.number}
                              sx={{ paddingX: '.5rem', whiteSpace: 'nowrap' }}
                              align="center">
                              {isTableEdited
                                ? renderCellEdit(header.key, week)
                                : formatStringCurrency(getDataType[header.key][week.key], header.key !== 'weekly_cpi')}
                            </TableCell>
                          )
                        })
                      : weekHeaders.map(week => {
                          return (
                            <TableCell
                              size="small"
                              key={week.number}
                              sx={{ paddingX: '.5rem', whiteSpace: 'nowrap' }}
                              align="center">
                              {formatStringCurrency(getDataType[header.key][week.key], header.key !== 'weekly_cpi')}
                            </TableCell>
                          )
                        })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

PhaseDataCosts.propTypes = {
  cutWeek: PropTypes.object,
  estimatedPV: PropTypes.object.isRequired,
  finalProjectedDate: PropTypes.object,
  hasUpdatedEndDate: PropTypes.bool.isRequired,
  hasProjectedData: PropTypes.bool.isRequired,
  hitoPMOProjectionLPO: PropTypes.object,
  hitoPMOProjectionTTT: PropTypes.object,
  isTableEdited: PropTypes.bool.isRequired,
  lastEstimatedValue: PropTypes.number.isRequired,
  loadingData: PropTypes.bool.isRequired,
  phase: PropTypes.object.isRequired,
  projectedCostValues: PropTypes.array.isRequired,
  projectedWeeklyEVCostValues: PropTypes.array.isRequired,
  realAC: PropTypes.object.isRequired,
  today: PropTypes.object.isRequired,
  totalWeeksWithProjection: PropTypes.array.isRequired,
  weeklyCpi: PropTypes.object.isRequired,
  weeklyEV: PropTypes.object.isRequired,
  weekHeaders: PropTypes.array.isRequired,
  handleCancelEditTable: PropTypes.func.isRequired,
  handleChangeRowValue: PropTypes.func.isRequired,
  handleChangeCostsRowValue: PropTypes.func.isRequired,
  handleEditTable: PropTypes.func.isRequired,
  handleSaveTableData: PropTypes.func.isRequired,
  setWeekHeaders: PropTypes.func.isRequired
}

export default PhaseDataCosts
