export const useCurrency = () => {
  const priceStringConfig = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }

  const priceStringConfigExchange = {
    style: 'decimal',
    maximumFractionDigits: 4,
    minimumFractionDigits: 4
  }

  const formatStringCurrency = (amount, withSymbol = false, isExchange = false) => {
    if (amount === 0) return '0.00'
    return amount
      ? withSymbol
        ? `$ ${Number(String(amount).replaceAll(',', '')).toLocaleString('es-MX', isExchange ? priceStringConfigExchange : priceStringConfig)}`
        : Number(String(amount).replaceAll(',', '')).toLocaleString(
            'es-MX',
            isExchange ? priceStringConfigExchange : priceStringConfig
          )
      : ''
  }

  const formatNumericCurrency = amount => {
    return amount.replaceAll(',', '')
  }

  const onKeyPressValidation = event => {
    const key = event.keyCode

    if (key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46) {
      return
    }
    if (!/^[\d|\.|\,]+/.test(event.key)) event.preventDefault()
  }

  return { formatStringCurrency, formatNumericCurrency, onKeyPressValidation }
}
