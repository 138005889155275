export const useInputValidations = () => {
  const regExpNumber = /^\d*$/

  const validateNumericField = value => {
    return value === '' || regExpNumber.test(value)
  }

  const onKeyPressValidateDecimalNumber = event => {
    const key = event.keyCode

    if (key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46 || key === 9) {
      return
    }
    if (!/^[\d|\.|\,]+/.test(event.key)) event.preventDefault()
  }

  return { validateNumericField, onKeyPressValidateDecimalNumber }
}
