import * as Yup from 'yup'

export const projectUsersSchema = Yup.object().shape({
  assigned_users: Yup.array()
    .of(
      Yup.object().shape({
        project_roles: Yup.array()
          .min(1, 'Es necesario seleccionar al menos un rol')
          .required('Selecciona al menos un rol')
      })
    )
    .min(1, 'Es necesario seleccionar al menos un usuario')
})
