import { createSlice } from '@reduxjs/toolkit'

export const alertManagementSlice = createSlice({
  name: 'alertManagement',
  initialState: {
    open: false,
    message: '',
    severity: ''
  },
  reducers: {
    setAlert: (state, action) => {
      state.open = action.payload.open
      state.message = action.payload.message
      state.severity = action.payload.severity
    },
    setCloseAlert: state => {
      state.open = false
      state.message = ''
      state.severity = ''
    }
  }
})

export const { setAlert, setCloseAlert } = alertManagementSlice.actions

export default alertManagementSlice.reducer
