import { Clear, Search } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const SearchBar = ({ disabled, value, placeholder = 'Buscar por nombre', onChange, onClear }) => {
  return (
    <TextField
      disabled={disabled}
      className="searchbar"
      sx={{
        '& .MuiInputBase-root': {
          paddingLeft: '.5rem',
          paddingRight: '.5rem'
        }
      }}
      size="small"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: <Search sx={{ marginRight: '.25rem', color: '#999' }} />,
        endAdornment: (
          <IconButton className="show_clear_button" size="small" onClick={onClear}>
            <Clear />
          </IconButton>
        )
      }}
    />
  )
}

SearchBar.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
}

export default SearchBar
