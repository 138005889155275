import { SPI_COLORS } from 'utils/constants'

export const useSpiCpi = () => {
  const getSPIColor = spi => {
    const numberValue = Number(spi)
    if (numberValue < 0.9) {
      return SPI_COLORS.red
    }
    if (numberValue < 0.95) {
      return SPI_COLORS.orange
    }
    if (numberValue <= 1) {
      return SPI_COLORS.green
    }
    return SPI_COLORS.blue
  }

  const getSpiCpiColor = (spi, cpi) => {
    if (spi < 1) {
      if (cpi < 1) {
        return SPI_COLORS.red
      }
      return SPI_COLORS.orange
    }
    if (cpi < 1) {
      return SPI_COLORS.orange
    }
    if (spi === 1 && cpi === 1) {
      return SPI_COLORS.orange
    }
    return SPI_COLORS.green
  }

  return { getSpiCpiColor, getSPIColor }
}
