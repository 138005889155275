import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Tab, Tabs } from '@mui/material'
import Styles from './_Settings.module.scss'

const Settings = () => {
  const navigate = useNavigate()
  const user = useSelector(store => store.userManagement.user)

  const [tabValue, setTabValue] = useState('profile')

  const handleChangeTab = value => {
    setTabValue(value)
    navigate(value)
  }

  useEffect(() => {
    navigate(tabValue)
  }, [])

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <h1>Configuración</h1>
      {user && (
        <Box className={Styles.settings__container}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={(e, value) => handleChangeTab(value)}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', height: '100%', minWidth: '10rem' }}>
            <Tab value="profile" label="Perfil" />
            <Tab value="subscription" label="Subscripción" />
            <Tab value="entity" label="Mi empresa" />
          </Tabs>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <Outlet />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Settings
