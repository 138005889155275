import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PhaseService from 'api/services/phase.service'
import SideModal from 'components/SideModal/SideModal'
import Phase from 'models/Phase'
import { phaseFormSchema } from 'utils/schemas/phaseFormSchema'
import Styles from './_SideModalPhase.module.scss'

const SideModalPhase = ({ actionType, open, onCancel, onConfirm, selectedPhase, projectId }) => {
  const [loading, setLoading] = useState(false)
  const [phase, setPhase] = useState(new Phase())

  const handleSavePhase = async values => {
    try {
      const phaseSaved =
        actionType === 'create'
          ? await PhaseService.store({ ...values, project_id: projectId })
          : await PhaseService.update(phase.id, values)
      setLoading(false)
      onConfirm('success', phaseSaved)
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }

  const { errors, touched, values, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: phase,
    enableReinitialize: true,
    validationSchema: phaseFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSavePhase(vals)
    }
  })

  useEffect(() => {
    if (open) {
      if (actionType === 'update') {
        setPhase({
          ...selectedPhase,
          start_date: dayjs(selectedPhase.start_date),
          end_date: dayjs(selectedPhase.end_date)
        })
      } else {
        setPhase(new Phase())
        resetForm()
      }
    }
  }, [open])

  return (
    <SideModal
      loading={loading}
      title={`${actionType === 'create' ? 'Nueva' : 'Editar'} Fase`}
      onConfirmText={`${actionType === 'create' ? 'Crear' : 'Guardar'} Fase`}
      open={open}
      onCancel={onCancel}
      onConfirm={() => handleSubmit()}>
      <>
        <FormControl className={Styles.input}>
          <TextField
            required
            label="Nombre de la fase"
            name="name"
            size="small"
            variant="outlined"
            error={Boolean(touched.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            required
            label="Descripción de la fase"
            name="description"
            multiline
            rows={3}
            size="small"
            variant="outlined"
            error={Boolean(touched.description && errors?.description)}
            helperText={touched?.description && errors?.description}
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha de inicio"
              format="DD/MM/YYYY"
              value={values.start_date}
              name="start_date"
              onChange={value => setFieldValue('start_date', value)}
              slotProps={{
                textField: {
                  name: 'start_date',
                  size: 'small',
                  required: true,
                  error: Boolean(touched.start_date && errors?.start_date),
                  helperText: touched?.start_date && errors?.start_date,
                  onBlur: handleBlur
                }
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl className={Styles.input}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha de fin"
              format="DD/MM/YYYY"
              minDate={dayjs(values.start_date).add(1, 'day')}
              disabled={values.start_date === null}
              value={values.end_date > values.start_date ? values.end_date : null}
              onChange={value => setFieldValue('end_date', value)}
              slotProps={{
                textField: {
                  name: 'end_date',
                  size: 'small',
                  required: true,
                  error: Boolean(touched.end_date && errors?.end_date),
                  helperText: touched?.end_date && errors?.end_date,
                  onBlur: handleBlur
                }
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </>
    </SideModal>
  )
}

SideModalPhase.propTypes = {
  actionType: PropTypes.string,
  open: PropTypes.bool,
  selectedPhase: PropTypes.object,
  projectId: PropTypes.number,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalPhase
