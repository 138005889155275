import axios from '../axios'

class PhaseService {
  static async store(params) {
    const resp = await axios.post('/phases', params)
    return resp.data
  }

  // static async findAll() {
  //   const resp = await axios.get('/phases')
  //   return resp.data
  // }

  static async find(id) {
    const resp = await axios.get(`/phases/${id}`)
    return resp.data
  }

  // static async findAllByProject(id) {
  //   const resp = await axios.get(`/phases/project/${id}`)
  //   return resp.data
  // }

  static async update(id, params) {
    const resp = await axios.put(`/phases/${id}`, { ...params })
    return resp.data
  }

  static async deleteWeek(id, params) {
    const resp = await axios.put(`/phases/${id}/delete_week`, { ...params })
    return resp.data
  }

  static async savePhaseData(id, params) {
    const resp = await axios.put(`/phases/${id}/data`, { ...params })
    return resp.data
  }

  static async savePhaseCostData(id, params) {
    const resp = await axios.put(`/phases/${id}/costs`, { ...params })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/phases/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/phases/restore/${id}`)
    return resp.data
  }
}

export default PhaseService
