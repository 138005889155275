import { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Collapse, Grid } from '@mui/material'
import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsVoronoiHandler,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  ResponsiveChartContainer,
  ScatterPlot
} from '@mui/x-charts'
import { useAuth } from 'hooks/useAuth'
import ProjectService from 'api/services/project.service'
import UserService from 'api/services/user.service'
import DataTable from 'components/DataTable/DataTable'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import SearchBar from 'components/SearchBar/SearchBar'
import { useAlert } from 'hooks/useAlert'
import { useSearchBar } from 'hooks/useSearchBar'
import { useSpiCpi } from 'hooks/useSpiCpi'
import SideModalProject from './components/SideModalProject/SideModalProject'
// import TestScatter from './components/TestScatter/TestScatter'

const Projects = () => {
  const { setAlert } = useAlert()
  const { isSystemObserver } = useAuth()
  const { getSpiCpiColor } = useSpiCpi()
  const { filterTableRowsByKey } = useSearchBar()

  const [loading, setLoading] = useState(true)
  const [hasProjects, setHasProjects] = useState(false)
  const [showGraphics, setShowGraphics] = useState(true)
  const [projectManagerProjects, setProjectManagerProjects] = useState([])
  const [projectManagerProjectsFiltered, setProjectManagerProjectsFiltered] = useState([])
  const [riskAnalistProjects, setRiskAnalistProjects] = useState([])
  const [riskAnalistProjectsFiltered, setRiskAnalistProjectsFiltered] = useState([])
  const [planningAnalistProjects, setPlanningAnalistProjects] = useState([])
  const [planningAnalistProjectsFiltered, setPlanningAnalistProjectsFiltered] = useState([])
  const [projectObserverProjects, setProjectObserverProjects] = useState([])
  const [projectObserverProjectsFiltered, setProjectObserverProjectsFiltered] = useState([])
  const [searchBarValue, setSearchBarValue] = useState('')
  const [selectedProject, setSelectedProject] = useState()
  const [sidemodalProject, setSidemodalProject] = useState({ show: false, actionType: '' })
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })
  const [series, setSeries] = useState([])

  const handleActions = (type, value) => {
    setSelectedProject(value)
    if (type === 'edit') {
      setSidemodalProject({ show: true, actionType: 'update' })
    } else {
      setModalDelete({ show: true, loading: false })
    }
  }

  const headers = [
    {
      header: 'Nombre',
      key: 'name',
      cellType: 'link'
    },
    {
      header: 'Fecha de inicio',
      key: 'start_date',
      cellType: 'date'
    },
    {
      header: 'Fecha de fin',
      key: 'end_contractual_date',
      cellType: 'date'
    },
    {
      header: 'SPI',
      key: 'spi',
      cellType: 'text'
    },
    {
      header: 'CPI',
      key: 'cpi',
      cellType: 'text'
    },
    {
      header: 'Riesgos',
      key: 'active_risks',
      cellType: 'text'
    },
    {
      header: 'Problemas',
      key: 'active_problems',
      cellType: 'text'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]
  if (isSystemObserver) headers.pop()

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        let value = element[header.key]

        if (header.key === 'name') {
          value = { label: element[header.key], url: `${element.id}` }
        } else if (header.key === 'spi' || header.key === 'cpi') {
          value = value || 'No definido'
        }

        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getSeriesObject = (role, project) => {
    return {
      type: 'scatter',
      id: `series-${project.id}-${role}`,
      data: [
        {
          x: Number(project.spi),
          y: Number(project.cpi),
          z: Number(project.spi) + Number(project.cpi),
          id: `project-${project.id}`
        }
      ],
      valueFormatter: value => `${project.name} --> SPI ${value.x || 'No definido'} - CPI ${value.y || 'No definido'}`,
      highlightScope: {
        highlight: 'item'
      },
      color: getSpiCpiColor(project.spi, project.cpi),
      markerSize: 10
    }
  }

  const getProjects = async () => {
    try {
      const resp = await UserService.findProjects()
      const pmProjects = orderData(resp.PROJECT_MANAGER)
      const raProjects = orderData(resp.RISK_ANALIST)
      const paProjects = orderData(resp.PLANNING_ANALIST)
      const poProjects = orderData(resp.PROJECT_OBSERVER)

      const seriesList = [
        ...resp.PROJECT_MANAGER.filter(item => item.spi && item.cpi).map(item => getSeriesObject('PM', item)),
        ...resp.RISK_ANALIST.filter(item => item.spi && item.cpi).map(item => getSeriesObject('RA', item)),
        ...resp.PLANNING_ANALIST.filter(item => item.spi && item.cpi).map(item => getSeriesObject('PA', item)),
        ...resp.PROJECT_OBSERVER.filter(item => item.spi && item.cpi).map(item => getSeriesObject('PO', item))
      ]

      setSeries(seriesList)

      setProjectManagerProjects(pmProjects)
      setProjectManagerProjectsFiltered(pmProjects)
      setRiskAnalistProjects(raProjects)
      setRiskAnalistProjectsFiltered(raProjects)
      setPlanningAnalistProjects(paProjects)
      setPlanningAnalistProjectsFiltered(paProjects)
      setProjectObserverProjects(poProjects)
      setProjectObserverProjectsFiltered(poProjects)

      if (pmProjects.length > 0 || raProjects.length > 0 || paProjects.length > 0 || poProjects.length > 0)
        setHasProjects(true)

      setLoading(false)
    } catch (error) {
      console.error(error)
      setAlert(true, 'error', 'Ocurrió un error al listar los proyectos, intenta de nuevo mas tarde')
    }
  }

  const handleSaveProject = result => {
    setSidemodalProject(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      let message = ''
      if (sidemodalProject.actionType === 'create') {
        message = 'Proyecto creado con éxito'
      } else {
        message = 'Proyecto guardado con éxito'
      }
      getProjects()
      setAlert(true, 'success', message)
    } else {
      setAlert(true, 'error', 'Ocurrió un error al guardar el Proyecto, intenta de nuevo más tarde')
    }
  }

  const handleCreateProject = () => {
    setSidemodalProject({ show: true, actionType: 'create' })
  }

  const handleConfirmDeleteProject = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await ProjectService.delete(selectedProject.id)
      setModalDelete({ show: false, loading: false })
      getProjects()
      setAlert(true, 'success', 'Proyecto eliminado con éxito')
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert(true, 'error', 'Ocurrió un error al eliminar el Proyecto, intenta de nuevo más tarde')
    }
  }

  const handleSearch = value => {
    setSearchBarValue(value)
    setProjectManagerProjectsFiltered(filterTableRowsByKey(projectManagerProjects, value))
    setRiskAnalistProjectsFiltered(filterTableRowsByKey(riskAnalistProjects, value))
    setPlanningAnalistProjectsFiltered(filterTableRowsByKey(planningAnalistProjects, value))
    setProjectObserverProjectsFiltered(filterTableRowsByKey(projectObserverProjects, value))
  }

  const handleClearSearchBar = () => {
    setSearchBarValue('')
    setProjectManagerProjectsFiltered(projectManagerProjects)
    setRiskAnalistProjectsFiltered(riskAnalistProjects)
    setPlanningAnalistProjectsFiltered(planningAnalistProjects)
    setProjectObserverProjectsFiltered(projectObserverProjects)
  }

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Mis Proyectos</h1>
        {hasProjects && (
          <Button
            size="small"
            variant="outlined"
            startIcon={showGraphics ? <VisibilityOff /> : <Visibility />}
            onClick={() => setShowGraphics(!showGraphics)}>
            {showGraphics ? 'Ocultar Gráficos' : 'Mostrar Gráficos'}
          </Button>
        )}
      </Box>
      <Collapse in={showGraphics} sx={{ marginBottom: showGraphics ? '.5rem' : '1.5rem' }}>
        <Grid container sx={{ justifyContent: 'center', marginTop: '-2rem' }}>
          <Grid item xs={6}>
            <Box sx={{ textAlign: 'center' }}>
              <h3>Relación SPI - CPI</h3>
            </Box>
            <Box sx={{ display: 'flex', marginTop: '.25rem' }}>
              <Box
                sx={{
                  writingMode: 'vertical-lr',
                  textAlign: 'center',
                  transform: 'rotate(180deg)',
                  fontSize: '1rem',
                  fontWeight: 600,
                  marginRight: '.25rem',
                  marginBottom: '.5rem'
                }}>
                Costo
              </Box>
              <ResponsiveChartContainer
                xAxis={[
                  {
                    min: 0,
                    max: 2
                  }
                ]}
                yAxis={[
                  {
                    tickNumber: 10,
                    min: 0,
                    max: 2
                  }
                ]}
                margin={{ left: 30, top: 10, bottom: 30, right: 10 }}
                height={180}
                series={series}>
                <ChartsGrid horizontal vertical />
                <ScatterPlot />
                <ChartsXAxis />
                <ChartsYAxis />
                <ChartsLegend />
                <ChartsVoronoiHandler voronoiMaxRadius={15} />

                <ChartsReferenceLine
                  y={1}
                  lineStyle={{
                    stroke: '#000000',
                    strokeWidth: 2,
                    shapeRendering: 'auto'
                  }}
                />
                <ChartsReferenceLine
                  x={1}
                  lineStyle={{
                    stroke: '#000000',
                    strokeWidth: 2,
                    shapeRendering: 'auto'
                  }}
                />

                <LineHighlightPlot />
                <ChartsAxisHighlight x="line" y="line" />
                <ChartsTooltip trigger="item" />
              </ResponsiveChartContainer>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: 600,
                marginLeft: '3rem'
              }}>
              Tiempo
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Box
        sx={{
          margin: '0 0 1rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <SearchBar
          disabled={!hasProjects}
          onChange={e => handleSearch(e.target.value)}
          value={searchBarValue}
          onClear={handleClearSearchBar}
        />
        {!isSystemObserver && (
          <Button variant="contained" color="primary" size="small" onClick={handleCreateProject}>
            Nuevo Proyecto
          </Button>
        )}
      </Box>
      {loading ? (
        <DataTable
          loading={loading}
          headers={headers}
          data={projectManagerProjectsFiltered}
          cellSx={{ padding: '.25rem' }}
        />
      ) : hasProjects ? (
        <>
          {projectManagerProjectsFiltered.length > 0 && (
            <Box sx={{ margin: '0 0 2rem' }}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <h3>Administrador de Proyecto</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable
                    loading={loading}
                    headers={headers}
                    data={projectManagerProjectsFiltered}
                    cellSx={{ padding: '.25rem' }}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {riskAnalistProjectsFiltered.length > 0 && (
            <Box sx={{ margin: '0 0 2rem' }}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <h3>Analista de Riesgos</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable loading={loading} headers={headers} data={riskAnalistProjectsFiltered} />
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {planningAnalistProjectsFiltered.length > 0 && (
            <Box sx={{ margin: '0 0 2rem' }}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <h3>Analista de Planeación</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable loading={loading} headers={headers} data={planningAnalistProjectsFiltered} />
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {projectObserverProjectsFiltered.length > 0 && (
            <Box sx={{ margin: '0 0 2rem' }}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <h3>Visualizador de Proyecto</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable loading={loading} headers={headers} data={projectObserverProjectsFiltered} />
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '5rem' }}>
          <h3>Aún no estás asignado a ningún proyecto</h3>
        </Box>
      )}

      {sidemodalProject.show && (
        <SideModalProject
          open={sidemodalProject.show}
          actionType={sidemodalProject.actionType}
          selectedProject={selectedProject}
          onCancel={() => setSidemodalProject(prevState => ({ ...prevState, show: false }))}
          onConfirm={handleSaveProject}
        />
      )}
      <ModalDelete
        entity="Proyecto"
        element={selectedProject}
        open={modalDelete.show}
        onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleConfirmDeleteProject}
        loading={modalDelete.loading}
      />
    </>
  )
}

export default Projects
