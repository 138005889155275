import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const OwnerProtectedRoute = ({ children }) => {
  const user = useSelector(store => store.userManagement.user)
  return user.is_owner === 1 ? children : <Navigate to="/" />
}

OwnerProtectedRoute.propTypes = {
  children: PropTypes.element
}

export default OwnerProtectedRoute
