import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, ListItemIcon } from '@mui/material'
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'
import { ROLE_ADMIN, ROLE_DEVELOPER } from 'utils/constants'
import Styles from './_SideMenu.module.scss'

const SideMenu = ({ show }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const authUser = useSelector(store => store.userManagement.user)

  return (
    <Box className={`${Styles.sidemenu} ${!show && Styles.hide}`}>
      <Box className={Styles.sidemenu__icon}>
        <img src="/img/Logo_SideMenu.png" alt="" />
      </Box>
      <hr />
      {authUser && (
        <Box className={Styles.menu}>
          {/* <Box className={`${Styles.menu__item} ${pathname === '/' && Styles.selected}`} onClick={() => navigate('/')}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <HomeOutlinedIcon color="neutral" />
            </ListItemIcon>
            Home
          </Box> */}
          <Box
            className={`${Styles.menu__item} ${pathname.includes('projects') && Styles.selected}`}
            onClick={() => navigate('projects')}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <DnsOutlinedIcon color="neutral" />
            </ListItemIcon>
            Mis Proyectos
          </Box>
          {(authUser.role_id === ROLE_ADMIN || authUser.role_id === ROLE_DEVELOPER) && (
            <Box
              className={`${Styles.menu__item} ${pathname.includes('users') && Styles.selected}`}
              onClick={() => navigate('users')}>
              <ListItemIcon className={Styles.menu__item_icon}>
                <PeopleAltOutlinedIcon color="neutral" />
              </ListItemIcon>
              Usuarios
            </Box>
          )}
          {/* <Box
            className={`${Styles.menu__item} ${pathname.includes('configuration') && Styles.selected}`}
            onClick={() => {}}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <SettingsOutlinedIcon color="neutral" />
            </ListItemIcon>
            Configuración
          </Box> */}
        </Box>
      )}
    </Box>
  )
}

SideMenu.propTypes = {
  show: PropTypes.bool
}

export default SideMenu
