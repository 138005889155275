import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import PropTypes from 'prop-types'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import {
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer
} from '@mui/x-charts'
import RemoveIcon from '@mui/icons-material/Remove'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ProjectService from 'api/services/project.service'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import { useAuth } from 'hooks/useAuth'
import { useInputValidations } from 'hooks/useInputValidations'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { setProject } from '../../../../reduxStore/reducer/projectManagementSlice'
import Styles from './_PhaseData.module.scss'

const PhaseData = ({
  difference,
  estimatedDaysLPO,
  estimatedPercentage,
  finalProjectedDate,
  hasUpdatedEndDate,
  hasProjectedData,
  hitoPMOProjectionLPO,
  hitoPMOProjectionTTT,
  isTableEdited,
  lastEstimatedValue,
  loadingData,
  phase,
  projectedValues,
  spi,
  today,
  weekHeaders,
  weeklyPercentage,
  totalWeeksWithProjection,
  handleCancelEditTable,
  handleChangeRowValue,
  handleEstimatedPercentageCsv,
  handleEditTable,
  handleSaveTableData,
  handleWeeklyPercentageCsv,
  setWeekHeaders
}) => {
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  const dispatch = useDispatch()
  const { isSystemObserver } = useAuth()
  const { onKeyPressValidateDecimalNumber } = useInputValidations()
  const { project } = useOutletContext()
  const { isProjectObserver } = useProjectRoles()

  const [enableEdit, setEnableEdit] = useState(false)
  const [estimatedPercentageCsv, setEstimatedPercentageCsv] = useState('')
  const [weeklyPercentageCsv, setWeeklyPercentageCsv] = useState('')

  const leftHeaders = [
    {
      key: 'estimated_percentage',
      label: '% Avance Programado'
    },
    {
      key: 'weekly_percentage',
      label: '% Avance Real'
    },
    {
      key: 'difference',
      label: 'Dif Real - Programado'
    },
    {
      key: 'spi',
      label: 'SPI-Índice rendimiento cronograma'
    }
    // {
    //   key: 'estimated_days_lpo',
    //   label: 'Días estimados a conclusión'
    // }
  ]

  const calculateWeekHeaders = week => {
    let classes = ''
    if (
      dayjs(phase.start_date).isSame(week.start_date) ||
      dayjs(phase.start_date).isBetween(week.start_date, week.end_date)
    ) {
      classes += `${Styles.start_date} `
    } else if (
      dayjs(phase.end_date).isSame(week.start_date) ||
      dayjs(phase.end_date).isBetween(week.start_date, week.end_date)
    ) {
      classes += `${Styles.end_date} `
    } else if (week.number === today.week) {
      classes += `${Styles.today} `
    }

    return classes
  }

  const handleAddWeek = async () => {
    if (lastEstimatedValue !== 100) return
    try {
      const lastWeek = weekHeaders[weekHeaders.length - 1]
      await ProjectService.addWeek(project.id, {
        end_date_updated: hasUpdatedEndDate
          ? dayjs(project.end_date_updated).add(1, 'week')
          : dayjs(project.end_contractual_date).add(1, 'week'),
        week_number: lastWeek.number + 1
      })
      dispatch(
        setProject({
          ...project,
          end_date_updated: hasUpdatedEndDate
            ? dayjs(project.end_date_updated).add(1, 'week').format('YYYY-MM-DD')
            : dayjs(project.end_contractual_date).add(1, 'week').format('YYYY-MM-DD')
        })
      )
      const weeks = [...weekHeaders]
      weeks.push({
        label: `Sem ${lastWeek.number + 1}`,
        key: `week_${lastWeek.number + 1}`,
        number: lastWeek.number + 1,
        start_date: lastWeek.start_date.add(1, 'week'),
        cutoff_date: lastWeek.cutoff_date.add(1, 'week'),
        end_date: lastWeek.end_date.add(1, 'week')
      })
      setWeekHeaders(weeks)
      handleChangeRowValue('100.00', 'estimated_percentage', `week_${lastWeek.number + 1}`)
    } catch (error) {
      console.error(error)
    }
  }

  const handelRemoveWeek = async () => {
    try {
      const lastWeek = weekHeaders[weekHeaders.length - 1]
      await ProjectService.deleteWeek(project.id, {
        end_date_updated: dayjs(project.end_date_updated).subtract(1, 'week'),
        week_number: lastWeek.number
      })
      dispatch(
        setProject({
          ...project,
          end_date_updated: dayjs(project.end_date_updated).subtract(1, 'week').format('YYYY-MM-DD')
        })
      )
      const weeks = [...weekHeaders]
      weeks.pop()
      setWeekHeaders(weeks)
    } catch (error) {
      console.error(error)
    }
  }

  const getDataType = key => {
    switch (key) {
      case 'estimated_percentage':
        return estimatedPercentage
      case 'weekly_percentage':
        return weeklyPercentage
      case 'difference':
        return difference
      case 'spi':
        return spi
      case 'estimated_days_lpo':
        return estimatedDaysLPO
      default:
        break
    }
  }

  const handleBlurPercentages = (e, key, week) => {
    e.target.value !== '' && handleChangeRowValue(Number(e.target.value).toFixed(2), key, week.key)
  }

  const handleChangePercentages = (e, key, week) => {
    if (e.target.value === '' || Number(e.target.value) <= 100) {
      handleChangeRowValue(e.target.value, key, week.key)
    }
  }

  const renderCellEdit = (key, week) => {
    const keys = {
      estimated_percentage: dayjs(week.start_date).isAfter(project.end_contractual_date) ? (
        estimatedPercentage[week.key]
      ) : (
        <TextField
          size="small"
          value={estimatedPercentage[week.key]}
          InputProps={{
            inputProps: {
              sx: { minWidth: '2rem !important', padding: '3px 10px' }
            }
          }}
          onBlur={e => handleBlurPercentages(e, key, week)}
          onChange={e => handleChangePercentages(e, key, week)}
          onKeyDown={onKeyPressValidateDecimalNumber}
        />
      ),
      weekly_percentage: (
        <TextField
          size="small"
          value={weeklyPercentage[week.key]}
          InputProps={{
            inputProps: {
              sx: { minWidth: '2rem !important', padding: '3px 10px' }
            }
          }}
          onBlur={e => handleBlurPercentages(e, key, week)}
          onChange={e => handleChangePercentages(e, key, week)}
          onKeyDown={onKeyPressValidateDecimalNumber}
        />
      ),
      spi: spi[week.key],
      difference: difference[week.key],
      estimated_days_lpo: (
        <TextField
          size="small"
          value={estimatedDaysLPO[week.key]}
          InputProps={{
            inputProps: {
              sx: { minWidth: '2rem !important', padding: '3px 10px' }
            }
          }}
          onChange={e => handleChangeRowValue(e.target.value, key, week.key)}
          onKeyDown={onKeyPressValidateDecimalNumber}
        />
      )
    }
    return keys[key]
  }

  const onKeyPressTextArea = (e, setState) => {
    const { target } = e

    if (e.key === 'Tab') {
      e.preventDefault()
      const start = target.selectionStart
      const end = target.selectionEnd

      // set textarea value to: text before caret + tab + text after caret
      const value = `${target.value.substring(0, start)}\t${target.value.substring(end)}`
      setState(value)

      // put caret at right position again
      target.selectionEnd = start + 1
      target.selectionStart = target.selectionEnd
    } else {
      if ((e.ctrlKey && e.key === 'c') || (e.ctrlKey && e.key === 'v') || (e.ctrlKey && e.key === 'z')) return
      onKeyPressValidateDecimalNumber(e)
    }
  }

  useEffect(() => {
    setEnableEdit(!isSystemObserver && !isProjectObserver)
  }, [isSystemObserver, isProjectObserver])

  useEffect(() => {
    if (isTableEdited) {
      setEstimatedPercentageCsv('')
      setWeeklyPercentageCsv('')
    }
  }, [isTableEdited])

  return (
    <>
      <Box sx={{ marginBottom: '2rem' }}>
        {weekHeaders.length > 0 &&
          (hasProjectedData ? (
            <ResponsiveChartContainer
              sx={{
                '& .MuiChartsAxis-directionX tspan': {
                  fontSize: '.8rem !important'
                },
                '& .MuiLineElement-root': {
                  strokeWidth: 2.5
                }
              }}
              xAxis={[
                {
                  tickNumber: 20,
                  min: dayjs(project.start_date).subtract(1, 'week'),
                  max:
                    finalProjectedDate && totalWeeksWithProjection.length > weekHeaders.length
                      ? dayjs(finalProjectedDate).add(1, 'week')
                      : project.end_date_updated
                        ? dayjs(project.end_date_updated).add(1, 'week')
                        : dayjs(project.end_contractual_date).add(1, 'week'),
                  data:
                    finalProjectedDate && totalWeeksWithProjection.length > weekHeaders.length
                      ? totalWeeksWithProjection
                      : weekHeaders.map(week => dayjs(week.cutoff_date)),
                  scaleType: 'time',
                  valueFormatter: value => dayjs(value).format('DD MMM YY'),
                  tickLabelStyle: {
                    angle: -35,
                    textAnchor: 'end'
                  }
                }
              ]}
              yAxis={[
                {
                  tickNumber: 10,
                  min: 0,
                  max: 100,
                  valueFormatter: value => `${value}%`
                }
              ]}
              height={450}
              series={[
                {
                  type: 'line',
                  data: weekHeaders.map(week => estimatedPercentage[week.key]).filter(item => item !== ''),
                  area: true,
                  showMark: false,
                  color: '#9c27b0',
                  label: 'Programado',
                  valueFormatter: value => (value ? `${value}%` : '')
                },
                {
                  type: 'line',
                  data: weekHeaders.map(week => weeklyPercentage[week.key]).filter(item => item !== ''),
                  area: true,
                  showMark: false,
                  color: '#082588',
                  label: 'Real',
                  valueFormatter: (value, { dataIndex }) =>
                    value ? `${value}% \n SPI: ${spi[`week_${dataIndex + 1}`]}` : ''
                },
                {
                  type: 'line',
                  data: projectedValues,
                  area: true,
                  showMark: false,
                  color: '#66bb6a',
                  label: 'Proyectado',
                  valueFormatter: value => (value ? `${value}%` : '')
                }
              ]}>
              <LinePlot />
              <ChartsXAxis />
              <ChartsYAxis />
              <ChartsLegend />
              <MarkPlot />

              {/* Inician las línes de refrencia de los HITOs */}
              {finalProjectedDate && (
                <ChartsReferenceLine
                  x={hitoPMOProjectionTTT.date}
                  lineStyle={{ strokeDasharray: '10 5', stroke: '#66bb6a', strokeWidth: 2, shapeRendering: 'auto' }}
                  labelStyle={{ fontSize: '10', stroke: '#66bb6a' }}
                  label={`${hitoPMOProjectionTTT.date.format('DD/MMM/YY')}\n${project.ttt_dates_label}\n(TEAC)`}
                  labelAlign="start"
                />
              )}
              <ChartsReferenceLine
                x={new Date(project.end_contractual_date)}
                lineStyle={{ strokeDasharray: '10 5', stroke: '#9c27b0', strokeWidth: 2, shapeRendering: 'auto' }}
                labelStyle={{ fontSize: '10', stroke: '#9c27b0' }}
                label={`${dayjs(project.end_contractual_date).format('DD/MMM/YY')}\n${project.ttt_dates_label}`}
                labelAlign="end"
              />
              {finalProjectedDate && project.show_lpo_dates && hitoPMOProjectionLPO.date && (
                <ChartsReferenceLine
                  x={hitoPMOProjectionLPO.date}
                  lineStyle={{ strokeDasharray: '10 5', stroke: '#00bbfd', strokeWidth: 2, shapeRendering: 'auto' }}
                  labelStyle={{ fontSize: '10', stroke: '#00bbfd' }}
                  label={`${hitoPMOProjectionLPO.date.format('DD/MMM/YY')}\n${project.lpo_dates_label}\n(TEAC)`}
                  labelAlign="middle"
                />
              )}
              {project.show_lpo_dates && project.lpo_contractual_date && (
                <ChartsReferenceLine
                  x={new Date(project.lpo_contractual_date)}
                  lineStyle={{ strokeDasharray: '10 5', stroke: '#082588', strokeWidth: 2, shapeRendering: 'auto' }}
                  labelStyle={{ fontSize: '10', stroke: '#082588' }}
                  label={`${dayjs(project.lpo_contractual_date).format('DD/MMM/YY')}\n${project.lpo_dates_label}`}
                  labelAlign="start"
                />
              )}
              {/* Terminan las línes de refrencia de los HITOs */}

              <LineHighlightPlot />
              <ChartsAxisHighlight x="line" />
              {/* Pendiente, posiblemente se pueda mejorar */}
              <ChartsTooltip trigger="axis" />
            </ResponsiveChartContainer>
          ) : (
            <ResponsiveChartContainer
              sx={{
                '& .MuiChartsAxis-directionX tspan': {
                  fontSize: '.8rem !important'
                }
              }}
              xAxis={[
                {
                  tickNumber: 20,
                  min: dayjs(project.start_date).subtract(1, 'week'),
                  max: project.end_date_updated
                    ? dayjs(project.end_date_updated).add(1, 'week')
                    : dayjs(project.end_contractual_date).add(1, 'week'),
                  data: weekHeaders.map(week => dayjs(week.cutoff_date)),
                  scaleType: 'time',
                  valueFormatter: value => dayjs(value).format('DD MMM YY'),
                  tickLabelStyle: {
                    angle: -35,
                    textAnchor: 'end'
                  }
                }
              ]}
              yAxis={[
                {
                  tickNumber: 10,
                  min: 0,
                  max: 100,
                  valueFormatter: value => `${value}%`
                }
              ]}
              height={450}
              series={[
                {
                  type: 'line',
                  data: weekHeaders.map(week => weeklyPercentage[week.key]).filter(item => item !== ''),
                  area: true,
                  showMark: false,
                  color: '#082588',
                  label: 'Real',
                  valueFormatter: (value, { dataIndex }) =>
                    value ? `${value}% \n SPI: ${spi[`week_${dataIndex + 1}`]}` : ''
                },
                {
                  type: 'line',
                  data: weekHeaders.map(week => estimatedPercentage[week.key]).filter(item => item !== ''),
                  area: true,
                  showMark: false,
                  color: '#9c17dd',
                  label: 'Programado',
                  valueFormatter: value => (value ? `${value}%` : '')
                }
              ]}>
              <LinePlot />
              <ChartsXAxis />
              <ChartsYAxis />
              <ChartsLegend />
              <MarkPlot />
              {project.show_lpo_dates && project.lpo_contractual_date && (
                <ChartsReferenceLine
                  x={new Date(project.lpo_contractual_date)}
                  lineStyle={{ strokeDasharray: '10 5', stroke: '#00bbfd', strokeWidth: 2 }}
                  labelStyle={{ fontSize: '10', stroke: 'red' }}
                  label={`${dayjs(project.lpo_contractual_date).format('DD/MMM/YY')}\nLPO`}
                  labelAlign="start"
                />
              )}
              <ChartsReferenceLine
                x={new Date(project.end_contractual_date)}
                lineStyle={{ strokeDasharray: '10 5', stroke: '#edc949', strokeWidth: 2 }}
                labelStyle={{ fontSize: '10', stroke: 'red' }}
                label={`${dayjs(project.end_contractual_date).format('DD/MMM/YY')}\nTTT`}
                labelAlign="start"
              />
              <LineHighlightPlot />
              <ChartsAxisHighlight x="line" />
              {/* Pendiente, posiblemente se pueda mejorar */}
              <ChartsTooltip trigger="axis" />
            </ResponsiveChartContainer>
          ))}
      </Box>
      {enableEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
            padding: '1rem 0',
            borderBottom: '1px solid #ddd',
            borderTop: '1px solid #ddd'
          }}>
          <h3>Tabla de datos por semana</h3>
          {isTableEdited ? (
            <Box>
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: '1rem' }}
                disabled={loadingData}
                onClick={handleCancelEditTable}>
                Cancelar
              </Button>
              <Button variant="contained" disabled={loadingData} onClick={handleSaveTableData}>
                Guardar Datos
              </Button>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<EditOutlinedIcon />} onClick={handleEditTable}>
              Editar Datos
            </Button>
          )}
        </Box>
      )}
      <Box sx={{ position: 'relative' }}>
        {loadingData && (
          <Box className={Styles.overlay}>
            <CircularProgress size="2rem" sx={{ marginRight: '1rem' }} />
            Guardando datos . . .
          </Box>
        )}
        {isTableEdited && (
          <Grid container rowSpacing={2} sx={{ marginBottom: '1.5rem' }}>
            <Grid item xs={12}>
              <Alert severity="info">
                Puedes copiar y pegar desde un archivo .csv o .xslx, o ingresar manualmente los valores separados por
                una tabulación
              </Alert>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
              % Avance Programado
            </Grid>
            <Grid item sm={12} md={8} lg={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <textarea
                  style={{
                    whiteSpace: 'nowrap',
                    overflowY: 'hidden',
                    width: '100%',
                    resize: 'none',
                    fontSize: '1rem',
                    padding: '.5rem .75rem'
                  }}
                  value={estimatedPercentageCsv}
                  onKeyDown={e => onKeyPressTextArea(e, setEstimatedPercentageCsv)}
                  onChange={e => setEstimatedPercentageCsv(e.target.value)}
                />
                <LightTooltip title="Ingresa al menos un valor para poder cargar los datos en la tabla" arrow>
                  <span>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ marginLeft: '1rem' }}
                      disabled={estimatedPercentageCsv === ''}
                      onClick={() => handleEstimatedPercentageCsv(estimatedPercentageCsv, 'estimated_percentage')}>
                      Cargar datos
                    </Button>
                  </span>
                </LightTooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
              % Avance Real
            </Grid>
            <Grid item sm={12} md={8} lg={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <textarea
                  style={{
                    whiteSpace: 'nowrap',
                    overflowY: 'hidden',
                    width: '100%',
                    resize: 'none',
                    fontSize: '1rem',
                    padding: '.5rem .75rem'
                  }}
                  value={weeklyPercentageCsv}
                  onKeyDown={e => onKeyPressTextArea(e, setWeeklyPercentageCsv)}
                  onChange={e => setWeeklyPercentageCsv(e.target.value)}
                />
                <LightTooltip title="Ingresa al menos un valor para poder cargar los datos en la tabla" arrow>
                  <span>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ marginLeft: '1rem' }}
                      disabled={weeklyPercentageCsv === ''}
                      onClick={() => handleWeeklyPercentageCsv(weeklyPercentageCsv, 'weekly_percentage')}>
                      Cargar datos
                    </Button>
                  </span>
                </LightTooltip>
              </Box>
            </Grid>
          </Grid>
        )}
        <TableContainer sx={{ maxWidth: '100%', border: '0.5px solid #ddd', position: 'relative' }}>
          <Table
            sx={{
              borderCollapse: 'unset',
              '& .MuiTableCell-root': {
                borderRight: '1px solid #eee'
              }
            }}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={Styles.column_sticky_left}
                  sx={{ borderBottom: '2px solid #bebaba', fontWeight: '700' }}>
                  Datos
                </TableCell>
                {weekHeaders.map(week => {
                  return (
                    <TableCell
                      key={week.number}
                      className={`
                                ${Styles.column} ${Styles.column_header} ${calculateWeekHeaders(week)} ${dayjs(week.start_date).isBefore(project.end_contractual_date) ? '' : Styles.week_added}`}
                      sx={{
                        borderBottom: '2px solid #bebaba'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          lineHeight: 'normal'
                        }}>
                        {phase.name === 'General' &&
                          dayjs(week.start_date).isAfter(project.end_contractual_date) &&
                          week.number === weekHeaders.length && (
                            <LightTooltip
                              title="Al eliminar la semana, se eliminarán todos los datos relacionados en todas las fases"
                              arrow
                              placement="top">
                              <Button
                                size="small"
                                variant="contained"
                                color="error"
                                sx={{ minWidth: '1.2rem', height: '1.2rem', padding: '0', marginBottom: '.2rem' }}
                                onClick={handelRemoveWeek}>
                                <RemoveIcon sx={{ color: 'white' }} fontSize="small" />
                              </Button>
                            </LightTooltip>
                          )}
                        <Box sx={{ fontWeight: '600' }}>{week.label}</Box>
                        <small>{dayjs(week.cutoff_date).format('DD/MM/YYYY')}</small>
                      </Box>
                    </TableCell>
                  )
                })}
                {phase.name === 'General' && (
                  <TableCell
                    className={`${Styles.column} ${Styles.column_header} ${Styles.week_added}`}
                    sx={{
                      borderBottom: '2px solid #bebaba'
                    }}>
                    <LightTooltip title="Asegúrate de tener al 100% el avance programado" arrow placement="top">
                      <span>
                        <Button
                          disabled={lastEstimatedValue !== 100}
                          variant="contained"
                          sx={{ whiteSpace: 'normal', lineHeight: 'normal' }}
                          onClick={handleAddWeek}>
                          Agregar semana
                        </Button>
                      </span>
                    </LightTooltip>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {leftHeaders.map((header, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      size="small"
                      className={Styles.column_sticky_left}
                      sx={{ borderBottom: '1px solid #c3c3c3' }}>
                      {header.label}
                    </TableCell>
                    {enableEdit
                      ? weekHeaders.map(week => {
                          return (
                            <TableCell size="small" key={week.number} sx={{ paddingX: '.5rem' }} align="center">
                              {isTableEdited ? renderCellEdit(header.key, week) : getDataType(header.key)[week.key]}
                            </TableCell>
                          )
                        })
                      : weekHeaders.map(week => {
                          return (
                            <TableCell size="small" key={week.number} sx={{ paddingX: '.5rem' }} align="center">
                              {getDataType(header.key)[week.key]}
                            </TableCell>
                          )
                        })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

PhaseData.propTypes = {
  difference: PropTypes.object.isRequired,
  estimatedDaysLPO: PropTypes.object.isRequired,
  estimatedPercentage: PropTypes.object.isRequired,
  finalProjectedDate: PropTypes.object,
  hasUpdatedEndDate: PropTypes.bool.isRequired,
  hasProjectedData: PropTypes.bool.isRequired,
  hitoPMOProjectionLPO: PropTypes.object,
  hitoPMOProjectionTTT: PropTypes.object,
  isTableEdited: PropTypes.bool.isRequired,
  lastEstimatedValue: PropTypes.number.isRequired,
  loadingData: PropTypes.bool.isRequired,
  phase: PropTypes.object.isRequired,
  projectedValues: PropTypes.array.isRequired,
  spi: PropTypes.object.isRequired,
  today: PropTypes.object.isRequired,
  totalWeeksWithProjection: PropTypes.array.isRequired,
  weekHeaders: PropTypes.array.isRequired,
  weeklyPercentage: PropTypes.object.isRequired,
  handleCancelEditTable: PropTypes.func.isRequired,
  handleChangeRowValue: PropTypes.func.isRequired,
  handleEditTable: PropTypes.func.isRequired,
  handleEstimatedPercentageCsv: PropTypes.func,
  handleSaveTableData: PropTypes.func.isRequired,
  handleWeeklyPercentageCsv: PropTypes.func,
  setWeekHeaders: PropTypes.func.isRequired
}

export default PhaseData
