import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import ModalInactivity from 'components/ModalInactivity/ModalInactivity'
import { useAlert } from 'hooks/useAlert'
import { useAuth } from 'hooks/useAuth'
import SideMenu from './components/SideMenu/SideMenu'
import Styles from './_Layout.module.scss'
import TopMenu from './components/TopMenu/TopMenu'

const Layout = () => {
  const { handleClose } = useAlert()
  const { getAuth } = useAuth()
  const authUser = useSelector(store => store.userManagement.user)

  const { open, severity, message } = useSelector(store => store.alertManagement)
  const [showSidebar, setShowSidebar] = useState(true)
  const [isWarningModalOpen, setWarningModalOpen] = useState(false)

  // Initialization

  const eventTypes = ['keypress', 'mousemove', 'mousedown', 'scroll', 'touchmove', 'pointermove']
  const addEventListeners = listener => {
    eventTypes.forEach(type => {
      window.addEventListener(type, listener, false)
    })
  }
  const removeEventListeners = listener => {
    if (listener) {
      eventTypes.forEach(type => {
        window.removeEventListener(type, listener, false)
      })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      const createTimeout = () =>
        setTimeout(() => {
          setWarningModalOpen(true)
        }, 600000)

      let timeout = createTimeout()

      const listener = () => {
        if (!isWarningModalOpen) {
          clearTimeout(timeout)
          timeout = createTimeout()
        }
      }

      addEventListeners(listener)

      // Cleanup
      return () => {
        removeEventListeners(listener)
        clearTimeout(timeout)
      }
    }
  }, [isWarningModalOpen])

  useEffect(() => {
    getAuth()
  }, [])

  return (
    <Box className={Styles.wrapper}>
      <SideMenu show={showSidebar} />
      <Box className={`${Styles.layout} ${!showSidebar && Styles.hide_sidebar}`}>
        <TopMenu onMenuClick={() => setShowSidebar(!showSidebar)} />
        <Box className={Styles.layout__content}>{authUser && <Outlet />}</Box>
      </Box>
      {isWarningModalOpen && <ModalInactivity open={isWarningModalOpen} onCancel={() => setWarningModalOpen(false)} />}
      <AlertPopup open={open} severity={severity} message={message} handleClose={handleClose} />
    </Box>
  )
}

export default Layout
