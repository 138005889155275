export class User {
  id
  name
  last_name
  email
  phone
  cellphone
  role_id
  role
  password
  password_confirmation
  project_roles
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.last_name = ''
    this.email = ''
    this.phone = ''
    this.cellphone = ''
    this.role_id = 2
    this.role = undefined
    this.project_roles = []
    this.password = ''
    this.password_confirmation = ''
  }
}

export default User
