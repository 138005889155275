import axios from '../axios'

class AuthService {
  static async login(email, password) {
    const resp = await axios.post('/login', { email, password })
    return resp.data
  }

  static async logout() {
    const resp = await axios.get('/logout')
    return resp
  }

  static async getCurrentUser() {
    const resp = await axios.get('/user')
    return resp.data
  }

  static async updateCurrentUser(params) {
    const resp = await axios.put('/user', { ...params })
    return resp.data
  }

  static async updatePassword(params) {
    const resp = await axios.put(`/user/update_password`, { ...params })
    return resp.data
  }
}

export default AuthService
