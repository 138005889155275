export class Project {
  id
  name
  contract_number
  short_description
  large_description
  days_deadline
  days_manually
  costs
  amount
  amount_approved
  currency
  start_date
  cutoff_date
  end_contractual_date
  lpo_contractual_date
  end_date_updated
  show_lpo_dates
  lpo_dates_label
  ttt_dates_label
  phases
  parent_id
  subprojects
  parent_projects
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.contract_number = ''
    this.short_description = ''
    this.large_description = ''
    this.days_deadline = ''
    this.days_manually = ''
    this.costs = []
    this.amount = ''
    this.amount_approved = ''
    this.currency = 'MXN'
    this.start_date = null
    this.cutoff_date = null
    this.end_contractual_date = null
    this.lpo_contractual_date = null
    this.end_date_updated = null
    this.show_lpo_dates = true
    this.lpo_dates_label = 'LPO'
    this.ttt_dates_label = 'TTT'
    this.parent_id = null
    this.phases = []
    this.subprojects = []
    this.parent_projects = []
  }
}

export default Project
