import download from 'downloadjs'
import axios from '../axios'

class ProjectService {
  static async store(params) {
    const resp = await axios.post('/projects', params)
    return resp.data
  }

  static async find(id) {
    const resp = await axios.get(`/projects/${id}`)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/projects')
    return resp.data
  }

  static async findAllByUser() {
    const resp = await axios.get('/projects/user')
    return resp.data
  }

  static async findPhases(projectId) {
    const resp = await axios.get(`/projects/${projectId}/phases`)
    return resp.data
  }

  static async update(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}`, { ...params })
    return resp.data
  }

  static async updateDatesLabels(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}/dates_labels`, { ...params })
    return resp.data
  }

  static async delete(projectId) {
    const resp = await axios.delete(`/projects/${projectId}`)
    return resp.data
  }

  static async restore(projectId) {
    const resp = await axios.get(`/projects/restore/${projectId}`)
    return resp.data
  }

  /** WEEKS */
  static async addWeek(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}/add_week`, { ...params })
    return resp.data
  }

  static async deleteWeek(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}/delete_week`, { ...params })
    return resp.data
  }

  /** ROLES */
  static async findRoles() {
    const resp = await axios.get(`/projects/roles`)
    return resp.data
  }

  /** USERS */
  static async updateUsers(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}/users`, { ...params })
    return resp.data
  }

  /** RISKS */
  static async findRisks(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks`)
    return resp.data
  }

  static async findRisksFilters(projectId, params) {
    const resp = await axios.get(`/projects/${projectId}/risks/filters`, { params: { ...params } })
    return resp.data
  }

  static async downloadRisksReport(projectId, params) {
    const resp = await axios.get(`/projects/${projectId}/risks/download`, {
      params: { ...params },
      responseType: 'blob',
      headers: {
        Accept: params.format === 'pdf' ? 'application/pdf' : 'text/csv'
      }
    })
    const content = resp.headers['content-type']
    if (params.format === 'pdf') {
      download(resp.data, `Reporte de riesgos PDF`, content)
    } else {
      download(resp.data, `Reporte de riesgos CSV`, content)
    }

    return resp.data
  }

  static async findRisksProbabilitiesData(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks/probabilities`)
    return resp.data
  }

  static async findRisksCostImpactsData(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks/cost_impacts`)
    return resp.data
  }

  static async findRisksTimeImpactsData(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks/time_impacts`)
    return resp.data
  }

  static async findRisksStats(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks/stats`)
    return resp.data
  }

  static async updateRiskSettings(projectId, params) {
    const resp = await axios.put(`/projects/${projectId}/risks/settings`, { ...params })
    return resp.data
  }

  static async findRiskSettings(projectId) {
    const resp = await axios.get(`/projects/${projectId}/risks/settings`)
    return resp.data
  }
}

export default ProjectService
