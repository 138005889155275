import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Chip,
  MenuItem,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import SideModal from 'components/SideModal/SideModal'
import { riskAreas, riskMode, riskStatus, riskType, riskUrgency } from 'utils/constants'
import CollapseDropdown from 'components/CollapseDropdown/CollapseDropdown'
import { resetFilters, setFilters } from '../../../../reduxStore/reducer/riskFiltersManagementSlice'
import Styles from './_SideModalRiskFilters.module.scss'

const SideModalRiskFilters = ({ open, onCancel, riskSeverityLimits, onConfirm, phases }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const filters = useSelector(store => store.riskFiltersManagement)

  const handleFiltersChange = (key, value) => {
    dispatch(setFilters({ key: key, value: value }))
  }

  const handleFiltersCheckboxChange = (key, value) => {
    const newList = [...filters[key]]
    const indexExists = newList.findIndex(item => item === value)
    if (indexExists === -1) {
      newList.push(value)
    } else {
      newList.splice(indexExists, 1)
    }
    dispatch(setFilters({ key: key, value: newList }))
  }

  const handleClearFilters = () => {
    dispatch(resetFilters())
  }

  useEffect(() => {
    if (open) {
      setLoading(false)
    }
  }, [open])

  return (
    <SideModal
      noPadding
      loading={loading}
      title="Filtrar Riesgos por:"
      onConfirmText="Aplicar filtros"
      onCancelText="Limpiar filtros"
      open={open}
      onClose={onCancel}
      onCancel={handleClearFilters}
      onConfirm={() => [setLoading(true), onConfirm()]}>
      <>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Modalidad {filters.mode && <Chip size="small" label="1" color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <FormControl>
              <TextField
                select
                name="type"
                size="small"
                variant="outlined"
                placeholder="Selecciona el tipo"
                SelectProps={{
                  displayEmpty: true,
                  renderValue: value => {
                    if (value === '' || value === undefined) {
                      return <em>Selecciona una modalidad</em>
                    }
                    return riskMode.find(item => item.value === value).label
                  }
                }}
                value={filters.mode || ''}
                onChange={e => handleFiltersChange('mode', e.target.value)}>
                {riskMode.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Fases {filters.phases.length > 0 && <Chip size="small" label={filters.phases.length} color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <List sx={{ padding: 0, '& .MuiListItemButton-root': { padding: '0 .5rem', display: 'flex' } }}>
              {phases.map((phase, index) => {
                return (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton onClick={() => handleFiltersCheckboxChange('phases', phase.id)}>
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <Checkbox edge="start" disableRipple checked={filters.phases.indexOf(phase.id) !== -1} />
                      </ListItemIcon>
                      <Box>{phase.name}</Box>
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Áreas de Riesgo (EDR)
              {filters.areas.length > 0 && <Chip size="small" label={filters.areas.length} color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <List sx={{ padding: 0, '& .MuiListItemButton-root': { padding: '0 .5rem', display: 'flex' } }}>
              {riskAreas.map((area, index) => {
                return (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton onClick={() => handleFiltersCheckboxChange('areas', area.value)}>
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <Checkbox edge="start" disableRipple checked={filters.areas.indexOf(area.value) !== -1} />
                      </ListItemIcon>
                      <Box>{area.label}</Box>
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Fecha de registro{' '}
              {filters.creation_date_start ||
                (filters.creation_date_end && <Chip size="small" label="1" color="primary" />)}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <FormControl sx={{ marginBottom: '.5rem' }}>
              <Box>Desde</Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={filters.creation_date_start}
                  onChange={value => handleFiltersChange('creation_date_start', value)}
                  slotProps={{
                    textField: {
                      size: 'small'
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl>
              <Box>Hasta</Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={filters.creation_date_end}
                  minDate={filters.creation_date_start || null}
                  onChange={value => handleFiltersChange('creation_date_end', value)}
                  slotProps={{
                    textField: {
                      size: 'small'
                    }
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Severidad{' '}
              {filters.severity.length > 0 && <Chip size="small" label={filters.severity.length} color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <List sx={{ padding: 0, '& .MuiListItemButton-root': { padding: '0 .5rem', display: 'flex' } }}>
              <ListItem disablePadding dense>
                <ListItemButton onClick={() => handleFiltersCheckboxChange('severity', 'LOW')}>
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Checkbox
                      edge="start"
                      disableRipple
                      checked={filters.severity.indexOf('LOW') !== -1}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <Box>
                    Baja <small>(de 0 a {riskSeverityLimits?.low})</small>
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton role={undefined} onClick={() => handleFiltersCheckboxChange('severity', 'MEDIUM')}>
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Checkbox
                      edge="start"
                      disableRipple
                      checked={filters.severity.indexOf('MEDIUM') !== -1}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <Box>
                    Media{' '}
                    <small>
                      (de {riskSeverityLimits?.low} a {riskSeverityLimits?.medium})
                    </small>
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton role={undefined} onClick={() => handleFiltersCheckboxChange('severity', 'HIGH')}>
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Checkbox
                      edge="start"
                      disableRipple
                      checked={filters.severity.indexOf('HIGH') !== -1}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <Box>
                    Alta{' '}
                    <small>
                      (de {riskSeverityLimits?.medium} a {riskSeverityLimits?.high})
                    </small>
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Estado {filters.status.length > 0 && <Chip size="small" label={filters.status.length} color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <List sx={{ padding: 0, '& .MuiListItemButton-root': { padding: '0 .5rem', display: 'flex' } }}>
              {riskStatus.map((status, index) => {
                return (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton onClick={() => handleFiltersCheckboxChange('status', status.value)}>
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <Checkbox edge="start" disableRipple checked={filters.status.indexOf(status.value) !== -1} />
                      </ListItemIcon>
                      <Box>{status.label}</Box>
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Tipo de riesgo {filters.type && <Chip size="small" label="1" color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <FormControl>
              <TextField
                select
                name="type"
                size="small"
                variant="outlined"
                placeholder="Selecciona el tipo"
                SelectProps={{
                  displayEmpty: true,
                  renderValue: value => {
                    if (value === '' || value === undefined) {
                      return <em>Selecciona un tipo</em>
                    }
                    return riskType.find(item => item.value === value).label
                  }
                }}
                value={filters.type || ''}
                onChange={e => handleFiltersChange('type', e.target.value)}>
                {riskType.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </CollapseDropdown>
        <CollapseDropdown
          header={
            <Box className={Styles.header}>
              Urgencia{' '}
              {filters.urgency.length > 0 && <Chip size="small" label={filters.urgency.length} color="primary" />}
            </Box>
          }>
          <Box className={Styles.options_container}>
            <List sx={{ padding: 0, '& .MuiListItemButton-root': { padding: '0 .5rem', display: 'flex' } }}>
              {riskUrgency.map((urgency, index) => {
                return (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton onClick={() => handleFiltersCheckboxChange('urgency', urgency.value)}>
                      <ListItemIcon sx={{ minWidth: 'auto' }}>
                        <Checkbox edge="start" disableRipple checked={filters.urgency.indexOf(urgency.value) !== -1} />
                      </ListItemIcon>
                      <Box>{urgency.label}</Box>
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </CollapseDropdown>
      </>
    </SideModal>
  )
}

SideModalRiskFilters.propTypes = {
  open: PropTypes.bool,
  phases: PropTypes.array,
  riskSeverityLimits: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalRiskFilters
