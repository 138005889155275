export class Risk {
  title
  description
  type
  mode
  visible_cause
  affectation
  action_plan
  creation_date
  implementation_date
  has_deadline
  deadline_date
  deadline_days
  reminder_frequency
  responsable
  probability_score
  impact_score
  severity_value
  tracking
  project_id
  phase_id
  risk_area_id
  risk_impact_area_id
  risk_control_action_id
  risk_plan_status_id
  risk_status_id
  risk_strategy_id
  risk_urgency_id

  constructor() {
    this.title = ''
    this.description = ''
    this.type = ''
    this.mode = ''
    this.visible_cause = ''
    this.affectation = ''
    this.action_plan = ''
    this.creation_date = null
    this.implementation_date = null
    this.has_deadline = false
    this.deadline_date = null
    this.deadline_days = ''
    this.reminder_frequency = ''
    this.responsable = null
    this.probability_score = ''
    this.impact_score = ''
    this.severity_value = ''
    this.tracking = ''
    this.project_id = ''
    this.phase_id = ''
    this.risk_area_id = ''
    this.risk_impact_area_id = 2
    this.risk_control_action_id = ''
    this.risk_plan_status_id = ''
    this.risk_status_id = 1
    this.risk_strategy_id = ''
    this.risk_urgency_id = ''
  }
}

export default Risk
