export const useSearchBar = () => {
  const filterTableRowsByKey = (list, value, key = 'name') => {
    return list.filter(item => {
      const row = item.find(row => row.key === key)
      if (row.cellType === 'link') {
        if (row.value.label.toUpperCase().includes(value.toUpperCase())) return item
      } else if (row.value.toUpperCase().includes(value.toUpperCase())) return item
      return null
    })
  }
  return { filterTableRowsByKey }
}
