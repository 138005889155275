import { useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@mui/material'
import AuthService from 'api/services/auth.service'
import SideModal from 'components/SideModal/SideModal'
import { userUpdateFormSchema } from 'utils/schemas/userFormSchema'
import Styles from './_SideModalProfile.module.scss'

const SideModalProfile = ({ selectedUser, open, onCancel, onConfirm }) => {
  const [loading, setLoading] = useState(false)

  const handleSaveUser = async vals => {
    try {
      await AuthService.updateCurrentUser(vals)
      setLoading(false)
      onConfirm('success')
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }
  const { errors, touched, values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: selectedUser,
    enableReinitialize: true,
    validationSchema: userUpdateFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSaveUser(vals)
    }
  })

  return (
    <SideModal
      title="Actualizar Perfil"
      onConfirmText="Guardar cambios"
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      {selectedUser && (
        <>
          <FormControl className={Styles.input}>
            <TextField
              name="name"
              label="Nombre"
              size="small"
              variant="outlined"
              value={values.name}
              error={Boolean(touched?.name && errors.name)}
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              name="last_name"
              label="Apellidos"
              size="small"
              variant="outlined"
              value={values.last_name}
              error={Boolean(touched?.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              name="email"
              label="Email"
              size="small"
              variant="outlined"
              value={values.email}
              error={Boolean(touched?.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              name="cellphone"
              label="Telefono móvil"
              size="small"
              variant="outlined"
              value={values.cellphone}
              error={Boolean(touched?.cellphone && errors.cellphone)}
              helperText={touched.cellphone && errors.cellphone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              name="phone"
              label="Telefono fijo"
              size="small"
              variant="outlined"
              value={values.phone}
              error={Boolean(touched?.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
        </>
      )}
    </SideModal>
  )
}

SideModalProfile.propTypes = {
  selectedUser: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool
}

export default SideModalProfile
