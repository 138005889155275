export const currencies = [
  // {
  //   code: 'CRC',
  //   name: 'Colón Costarricense'
  // },
  // {
  //   code: 'AUD',
  //   name: 'Dólar Australiano'
  // },
  {
    code: 'CAD',
    name: 'Dólar Canadiense'
  },
  {
    code: 'USD',
    name: 'Dólar Estadounidense'
  },
  // {
  //   code: 'NZD',
  //   name: 'Dólar Neozelandés'
  // },
  {
    code: 'EUR',
    name: 'Euro'
  },
  {
    code: 'GBP',
    name: 'Libra Esterlina'
  },
  {
    code: 'CLP',
    name: 'Peso Chileno'
  },
  {
    code: 'COP',
    name: 'Peso Colombiano'
  },
  {
    code: 'MXN',
    name: 'Peso Mexicano'
  },
  {
    code: 'PEN',
    name: 'Nuevo Sol Peruano'
  },
  {
    code: 'BRL',
    name: 'Real Brasileño'
  },
  {
    code: 'JPY',
    name: 'Yen Japonés'
  }
]
