import PropTypes from 'prop-types'
import { Grid, Skeleton } from '@mui/material'

const SkeletonLoading = ({ type = 'general', size }) => {
  return (
    <>
      {type === 'phase' && (
        <>
          <Skeleton animation="wave" variant="rounded" width="100%" height={20} sx={{ marginBottom: '1rem' }} />
          <Skeleton animation="wave" variant="rounded" width="100%" height={20} sx={{ marginBottom: '1rem' }} />
          <Skeleton animation="wave" variant="rounded" width="100%" height={400} sx={{ marginBottom: '1rem' }} />
        </>
      )}
      {type === 'project' && (
        <>
          <Skeleton animation="wave" variant="rounded" width="100%" height={20} sx={{ marginBottom: '1rem' }} />
          <Skeleton animation="wave" variant="rounded" width="100%" height={20} sx={{ marginBottom: '1rem' }} />
          <Skeleton animation="wave" variant="rounded" width="100%" height={100} sx={{ marginBottom: '1rem' }} />
        </>
      )}
      {type === 'table' &&
        (size ? (
          [...Array(size).keys()].map(item => {
            return (
              <Grid key={item} container rowSpacing={1} columnSpacing={1} sx={{ padding: '.5rem' }}>
                <Grid item xs={4}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={4}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={1}>
                  <Skeleton animation="wave" variant="circular" height={25} width={25} />
                </Grid>
                <Grid item xs={4}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={4}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
                </Grid>
                <Grid item xs={1}>
                  <Skeleton animation="wave" variant="circular" height={25} width={25} />
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Grid container rowSpacing={1} columnSpacing={1} sx={{ padding: '.5rem' }}>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton animation="wave" variant="circular" height={25} width={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton animation="wave" variant="circular" height={25} width={25} />
            </Grid>
          </Grid>
        ))}
      {type === 'datatable' &&
        (size ? (
          [...Array(size).keys()].map(item => {
            return (
              <Grid key={item} container rowSpacing={2} columnSpacing={2} sx={{ padding: '.5rem' }}>
                <Grid item xs={3}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={30} />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={30} />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={30} />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={30} />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton animation="wave" variant="rounded" width="100%" height={30} />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex' }}>
                  <Skeleton animation="wave" variant="circular" height={30} width={30} />
                  <Skeleton animation="wave" variant="circular" height={30} width={30} />
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Grid container rowSpacing={1} columnSpacing={1} sx={{ padding: '.5rem' }}>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton animation="wave" variant="circular" height={25} width={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton animation="wave" variant="rounded" width="100%" height={25} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton animation="wave" variant="circular" height={25} width={25} />
            </Grid>
          </Grid>
        ))}
    </>
  )
}

SkeletonLoading.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number
}

export default SkeletonLoading
