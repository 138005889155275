import PropTypes from 'prop-types'
import { Backdrop, Box, Button, CircularProgress, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Styles from './_SideModal.module.scss'

const SideModal = ({
  open,
  children,
  title,
  loading = false,
  disabled,
  noPadding = false,
  onConfirmText,
  onCancelText,
  onConfirm,
  onCancel,
  onClose
}) => {
  return (
    <Backdrop open={open} sx={{ justifyContent: 'end' }} aria-modal>
      <Box className={Styles.sidemodal}>
        <Box className={Styles.sidemodal__header}>
          <Box>{title || 'Creation form'}</Box>
          {onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Box className={Styles.sidemodal__body}>
          <Box sx={{ padding: noPadding ? '0' : '1.5rem' }}>{children}</Box>
        </Box>
        <Box className={Styles.sidemodal__footer}>
          <Button
            fullWidth={true}
            variant="outlined"
            sx={{ marginRight: '.5rem' }}
            disabled={loading}
            onClick={onCancel}>
            {onCancelText || 'Cancelar'}
          </Button>
          <Button
            fullWidth={true}
            variant="contained"
            sx={{ marginLeft: '.5rem' }}
            disabled={loading || disabled}
            onClick={() => onConfirm()}>
            {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : onConfirmText || 'Guardar'}
          </Button>
        </Box>
      </Box>
    </Backdrop>
  )
}

SideModal.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmText: PropTypes.string,
  onClose: PropTypes.func
}

export default SideModal
