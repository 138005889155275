import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem('user'))
  return isAuthenticated ? children : <Navigate to="/login" />
}

ProtectedRoute.propTypes = {
  children: PropTypes.element
}

export default ProtectedRoute
