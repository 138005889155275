import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsVoronoiHandler,
  ChartsXAxis,
  ChartsYAxis,
  LineChart,
  LineHighlightPlot,
  ResponsiveChartContainer,
  ScatterPlot
} from '@mui/x-charts'
import { Alert, Box, Divider, Grid, LinearProgress } from '@mui/material'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ProjectService from 'api/services/project.service'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import { useCurrency } from 'hooks/useCurrency'
import { useDate } from 'hooks/useDate'
import { useSpiCpi } from 'hooks/useSpiCpi'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import { SPI_COLORS } from 'utils/constants'
import Styles from './_ProjectDetails.module.scss'

const ProjectDetail = () => {
  const { formatDate } = useDate()
  const { formatStringCurrency } = useCurrency()
  const { project } = useOutletContext()
  const { getSPIColor, getSpiCpiColor } = useSpiCpi()

  const [loading, setLoading] = useState(true)
  const [phasesData, setPhasesData] = useState([])
  const [phaseGeneral, setPhaseGeneral] = useState()
  const [loadingPhasesData, setLoadingPhasesData] = useState(false)
  const spiLegends = {
    high: 'SPI Mayor a 1 Tiempo Eficiente - Adelanto de un (valor del SPI sin el entero, ejemplo: SPI 1.13 = 13) % conforme al plazo programado del proyecto.',
    low: 'SPI Menor a 1 Tiempo Deficiente - Retraso de un (a 1, resta el valor de SPI, ejemplo: 1 - SPI 0.81 = 19) % conforme al plazo programado del proyecto'
  }
  const cpiLegends = {
    high: 'CPI Mayor a 1 Costo Eficiente - Eficiencia de un (valor del CPI sin el entero, ejemplo: CPI 1.28 = 28) % conforme al costo programado del proyecto.',
    low: 'CPI Menor a 1 Costo Deficiente - Gasto mayor, (a 1 resta el valor de CPI, ejemplo: 1 - CPI 0.81 = 19) % conforme al costo programado del proyecto.'
  }

  const getProjectPhases = async () => {
    setLoadingPhasesData(true)
    try {
      const resp = await ProjectService.findPhases(project.id)
      setPhasesData(resp)
      setPhaseGeneral(resp.find(phase => phase.name === 'General'))
      setLoadingPhasesData(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (project) {
      getProjectPhases()
      setLoading(false)
    }
  }, [project])

  return !loading && project ? (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Divider sx={{ margin: '0 0 1rem' }}>
            <Box sx={{ fontSize: '1.2rem' }}>Detalles de Proyecto</Box>
          </Divider>
          <Box>
            <b>Contrato:</b> {project.contract_number}
          </Box>
          <Box>
            <b>Descripción:</b> {project.large_description}
          </Box>
          <Divider sx={{ margin: '1rem 0' }}>
            <Box sx={{ fontSize: '1.2rem' }}>Costos</Box>
          </Divider>
          {project.costs.length > 0 && (
            <Box>
              <b>Monto del contrato en:</b>
            </Box>
          )}
          {project.costs.map((cost, index) => {
            return (
              <Box key={index}>
                - <span style={{ fontWeight: 600 }}>{cost.currency}:</span> $ {formatStringCurrency(cost.amount)}{' '}
                <small>
                  ($ {formatStringCurrency(cost.amount / cost.exchange)} {project.currency} | Tipo de cambio: $
                  {cost.exchange})
                </small>
              </Box>
            )
          })}
          <Box>
            - <span style={{ fontWeight: 600 }}> {project.currency}:</span> $ {formatStringCurrency(project.amount)}
          </Box>
          <Box sx={{ marginTop: '1rem' }}>
            <h3>
              <b>Monto aprobado en {project.currency}:</b> $ {formatStringCurrency(project.amount_approved)}
            </h3>
          </Box>
          <Divider sx={{ margin: '1rem 0' }}>
            <Box sx={{ fontSize: '1.2rem' }}>Indicadores</Box>
          </Divider>
          {phaseGeneral ? (
            <>
              <Box sx={{ marginBottom: '.5rem' }}>
                Datos hasta la fecha:{' '}
                {phaseGeneral.spi_data.length > 0 ? (
                  <>
                    <b>
                      {formatDate(
                        dayjs(project.cutoff_date).add(
                          phaseGeneral.spi_data[phaseGeneral.spi_data.length - 1].week_number - 1,
                          'weeks'
                        )
                      )}
                    </b>{' '}
                    &nbsp; | &nbsp; Semana: <b>{phaseGeneral.spi_data[phaseGeneral.spi_data.length - 1].week_number}</b>
                  </>
                ) : (
                  <span>Aún no hay valores registrados</span>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <h3>
                  <b>SPI:</b> {phaseGeneral?.spi || 'No definido'}
                  &nbsp; <small>(Schedule Performance Index)</small>
                </h3>
                {!phaseGeneral.spi && (
                  <LightTooltip title="No hay datos registrados para la semana de corte" arrow>
                    <WarningAmberOutlinedIcon sx={{ marginLeft: '.5rem' }} color="warning" />
                  </LightTooltip>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <h3>
                  <b>CPI:</b> {phaseGeneral.cpi || 'No definido'}
                  &nbsp; <small>(Cost Performance Index)</small>
                </h3>
                {!phaseGeneral.cpi && (
                  <LightTooltip title="No hay datos registrados para la semana de corte" arrow>
                    <WarningAmberOutlinedIcon sx={{ marginLeft: '.5rem' }} color="warning" />
                  </LightTooltip>
                )}
              </Box>
              <Box sx={{ display: 'flex', marginTop: '1rem' }}>
                <Box
                  sx={{
                    writingMode: 'vertical-lr',
                    textAlign: 'center',
                    transform: 'rotate(180deg)',
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    marginRight: '.25rem',
                    marginBottom: '.5rem'
                  }}>
                  Costo
                </Box>
                <ResponsiveChartContainer
                  xAxis={[
                    {
                      min: 0,
                      max: 2
                    }
                  ]}
                  yAxis={[
                    {
                      tickNumber: 10,
                      min: 0,
                      max: 2
                    }
                  ]}
                  margin={{ left: 30, top: 10, bottom: 30, right: 10 }}
                  height={300}
                  series={[
                    {
                      type: 'scatter',
                      id: 'series-1',
                      data: phaseGeneral.spi
                        ? [
                            {
                              x: phaseGeneral.spi,
                              y: phaseGeneral.cpi,
                              id: 'SPI',
                              z: phaseGeneral.spi + phaseGeneral.cpi
                            }
                          ]
                        : [],
                      valueFormatter: value => `SPI ${value.x || 'No definido'} - CPI ${value.y || 'No definido'}`,
                      highlightScope: {
                        highlight: 'item'
                      },
                      color: getSpiCpiColor(phaseGeneral.spi, phaseGeneral.cpi),
                      markerSize: 10
                    }
                  ]}>
                  <ChartsGrid horizontal vertical />
                  <ScatterPlot />
                  <ChartsXAxis />
                  <ChartsYAxis />
                  <ChartsLegend />
                  <ChartsVoronoiHandler voronoiMaxRadius={15} />

                  <ChartsReferenceLine
                    y={1}
                    lineStyle={{
                      stroke: '#000000',
                      strokeWidth: 2,
                      shapeRendering: 'auto'
                    }}
                  />
                  <ChartsReferenceLine
                    x={1}
                    lineStyle={{
                      stroke: '#000000',
                      strokeWidth: 2,
                      shapeRendering: 'auto'
                    }}
                  />
                  {/* Terminan las línes de refrencia de los HITOs */}

                  <LineHighlightPlot />
                  <ChartsAxisHighlight x="line" y="line" />
                  {/* Pendiente, posiblemente se pueda mejorar */}
                  <ChartsTooltip trigger="item" />
                </ResponsiveChartContainer>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  marginLeft: '3rem'
                }}>
                Tiempo
              </Box>
              <Box>
                {phaseGeneral?.spi && (
                  <Alert severity="info" sx={{ marginTop: '.5rem' }}>
                    {phaseGeneral.spi >= 1 ? spiLegends.high : spiLegends.low}
                  </Alert>
                )}
                {phaseGeneral?.cpi && (
                  <Alert severity="info" sx={{ marginTop: '.5rem' }}>
                    {phaseGeneral.cpi >= 1 ? cpiLegends.high : cpiLegends.low}
                  </Alert>
                )}
              </Box>
            </>
          ) : (
            <SkeletonLoading type="phase" />
          )}
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Divider sx={{ margin: '0 0 1rem' }}>
              <Box sx={{ fontSize: '1.2rem' }}>Plazo</Box>
            </Divider>
            <Box>
              <b>Fecha de inicio:</b> {formatDate(project.start_date)}
            </Box>
            <Box>
              <b>Fecha final contractual:</b> {formatDate(project.end_contractual_date)}
            </Box>
            <br />
            <Box>
              <b>Días de plazo:</b> {project.days_deadline} días
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <b>Días de plazo manual:</b>
              {project.days_manually ? (
                <>&nbsp;{project.days_manually} días</>
              ) : (
                <>
                  &nbsp;No definido&nbsp;
                  <LightTooltip title="Te recomendamos definir un plazo igual o cercano a los días de plazo" arrow>
                    <WarningAmberOutlinedIcon color="warning" fontSize="small" />
                  </LightTooltip>
                </>
              )}
            </Box>
            {project.end_date_updated && project.end_contractual_date !== project.end_date_updated && (
              <>
                <br />
                <Alert severity="info">
                  Se agregaron semanas debido a que en alguna de las fases se está tomando mas del plazo original del
                  proyecto para registrar el avance real, por lo tanto hay una nueva fecha de finalización del proyecto
                </Alert>
                <br />
                <Box>
                  <b>Fecha final actualizada:</b> {formatDate(project.end_date_updated)}
                </Box>
                <Box>
                  <b>Semanas agregadas:</b>{' '}
                  {dayjs(project.end_date_updated).diff(dayjs(project.end_contractual_date), 'weeks')}
                </Box>
              </>
            )}
          </Box>
          <Box className={Styles.popper}>
            <Divider sx={{ margin: '1rem 0' }}>
              <Box sx={{ fontSize: '1.2rem' }}>Seguimiento de Fases</Box>
            </Divider>
            <Box sx={{ textAlign: 'center', marginBottom: '.5rem' }}>Indicadores SPI</Box>
            <Grid container spacing={2} sx={{ marginBottom: '.7rem' }}>
              <Grid xs={2} item className={Styles.spi_indicators_item}>
                <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.blue }} />
                &gt; 1+
              </Grid>
              <Grid xs={3} item className={Styles.spi_indicators_item}>
                <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.green }} />
                &ge; 0.95 a 1
              </Grid>
              <Grid xs={4} item className={Styles.spi_indicators_item}>
                <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.orange }} />
                &ge; 0.90 a &lt; 0.95
              </Grid>
              <Grid xs={3} item className={Styles.spi_indicators_item}>
                <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.red }} />0 a
                &lt; 0.90
              </Grid>
              <Grid xs={1} item />
              <Grid xs={4} item sx={{ textAlign: 'center', marginTop: '.5rem' }}>
                <Box>Avance Programado</Box>
                <LinearProgress
                  className={Styles.phase_stats__item_progress_bar}
                  sx={{ width: '100%' }}
                  color="secondary"
                  variant="determinate"
                  value={80}
                />
              </Grid>
              <Grid xs={2} item />
              <Grid xs={4} item sx={{ textAlign: 'center', marginTop: '.5rem' }}>
                <Box>Avance Real</Box>
                <LinearProgress
                  className={Styles.phase_stats__item_progress_bar}
                  sx={{
                    width: '100%',
                    backgroundColor: '#cee2e1',
                    '& .MuiLinearProgress-bar': { backgroundColor: '#082588' }
                  }}
                  variant="determinate"
                  value={80}
                />
              </Grid>
              <Grid xs={1} item />
            </Grid>
            <Box className={Styles.phase_stats__container}>
              <Box className={Styles.phase_stats__header}>Fases</Box>
              <Box className={Styles.phase_stats__body}>
                {loadingPhasesData ? (
                  <SkeletonLoading type="table" size={3} />
                ) : (
                  phasesData.map((phase, index) => {
                    return (
                      <Box key={index} className={Styles.phase_stats__item}>
                        <Grid container>
                          <Grid item xs={4} className={Styles.phase_stats__item_name}>
                            <b>{phase.name}</b>
                          </Grid>
                          <Grid item xs={2} className={Styles.phase_stats__item_value}>
                            <small style={{ marginBottom: '.25rem' }}>
                              {phase.estimated_percentage ? phase.estimated_percentage : 0}%
                            </small>
                            <small>{phase.weekly_percentage ? phase.weekly_percentage : 0}%</small>
                          </Grid>
                          <Grid item xs={4} className={Styles.phase_stats__item_progress}>
                            <LinearProgress
                              className={Styles.phase_stats__item_progress_bar}
                              color="secondary"
                              variant="determinate"
                              value={Number(phase.estimated_percentage)}
                            />
                            <LinearProgress
                              className={Styles.phase_stats__item_progress_bar}
                              sx={{
                                backgroundColor: '#cee2e1',
                                marginTop: '.5rem',
                                '& .MuiLinearProgress-bar': { backgroundColor: '#082588' }
                              }}
                              variant="determinate"
                              value={Number(phase.weekly_percentage)}
                            />
                          </Grid>
                          <Grid item xs={2} className={Styles.phase_stats__item_name}>
                            <Box
                              className={Styles.phase_stats__item_circle}
                              sx={{ backgroundColor: getSPIColor(phase.spi) }}
                            />
                            <Box sx={{ marginLeft: '.5rem' }}>{phase.spi}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {phaseGeneral ? (
            <>
              <Divider sx={{ margin: '0 0 1rem' }}>
                <Box sx={{ fontSize: '1.2rem' }}>Histograma SPI - CPI</Box>
              </Divider>
              <Box>
                Desde: <b>{formatDate(project.start_date)}</b> &nbsp; - &nbsp; Hasta:{' '}
                {phaseGeneral.spi_data.length > 0 ? (
                  <>
                    <b>
                      {formatDate(
                        dayjs(project.cutoff_date).add(
                          phaseGeneral.spi_data[phaseGeneral.spi_data.length - 1].week_number - 1,
                          'weeks'
                        )
                      )}
                    </b>{' '}
                    &nbsp; | &nbsp; Semana: <b>{phaseGeneral.spi_data[phaseGeneral.spi_data.length - 1].week_number}</b>
                  </>
                ) : (
                  <span>Aún no hay valores registrados</span>
                )}
              </Box>
              <Box>
                <LineChart
                  sx={{
                    '& .MuiLineElement-root': {
                      strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-mark': {
                      display: 'none'
                    }
                  }}
                  colors={['#ce938b', '#88caa3']}
                  grid={{ horizontal: true }}
                  height={400}
                  slotProps={{
                    noDataOverlay: { message: 'No hay datos para mostrar' }
                  }}
                  xAxis={[
                    {
                      data: phaseGeneral.spi_data.map(item => item.week_number),
                      scaleType: 'linear',
                      valueFormatter: value => `Sem ${value}`,
                      tickLabelStyle: {
                        angle: -35,
                        textAnchor: 'end'
                      },
                      hideTooltip: true
                    }
                  ]}
                  series={
                    phaseGeneral.spi_data.length === 0 && phaseGeneral?.cpi_costs_data.length === 0
                      ? []
                      : [
                          {
                            label: 'SPI',
                            curve: 'linear',
                            showMark: false,
                            data: phaseGeneral?.spi_data.map(item => item.spi),
                            valueFormatter: value => `${value || 'No registrado'}`
                          },
                          {
                            label: 'CPI',
                            curve: 'linear',
                            showMark: false,
                            data: phaseGeneral?.cpi_costs_data.map(item => item.weekly_cpi),
                            valueFormatter: value => `${value || 'No registrado'}`
                          }
                        ]
                  }
                />
              </Box>
            </>
          ) : (
            <SkeletonLoading type="project" />
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <SkeletonLoading type="project" />
  )
}

export default ProjectDetail
