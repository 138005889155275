import OwnerProtectedRoute from 'components/OwnerProtectedRoute/OwnerProtectedRoute'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import React from 'react'
import NotFound from 'views/NotFound/NotFound'
import Home from 'views/Home/Home'
import Layout from 'views/Layout/Layout'
import Login from 'views/Login/Login'
import Profile from 'views/Profile/Profile'
import Projects from 'views/Projects/Projects'
import Phases from 'views/Projects/views/Phases/Phases'
import ProjectDetail from 'views/Projects/views/ProjectDetails/ProjectDetail'
import ProjectLayout from 'views/Projects/views/ProjectLayout/ProjectLayout'
import Risks from 'views/Projects/views/Risks/Risks'
// import SubProjects from 'views/Projects/views/SubProjects/SubProjects'
// import Register from 'views/Register/Register'
import Settings from 'views/Settings/Settings'
import Entity from 'views/Settings/views/Entity/Entity'
import Subscription from 'views/Settings/views/Subscription/Subscription'
import Users from 'views/Users/Users'

const routes = [
  {
    path: '',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'projects',
        element: <Projects />
      },
      {
        path: 'projects/:projectId',
        element: <ProjectLayout />,
        children: [
          {
            path: '',
            element: <ProjectDetail />
          },
          {
            path: 'phases',
            element: <Phases />
          },
          // {
          //   path: 'subprojects',
          //   element: <SubProjects />
          // },
          {
            path: 'risks',
            element: <Risks />
          }
        ]
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'settings',
        element: (
          <OwnerProtectedRoute>
            <Settings />
          </OwnerProtectedRoute>
        ),
        children: [
          {
            path: 'profile',
            element: <Profile />
          },
          {
            path: 'subscription',
            element: <Subscription />
          },
          {
            path: 'entity',
            element: <Entity />
          }
        ]
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  /* Comentado mientras se desarrolla el regis por Stripe */
  // {
  //   path: 'register',
  //   element: <Register />
  // }
  {
    path: '*',
    element: <NotFound />
  }
]

export default routes
