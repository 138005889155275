import { Box, Button, CircularProgress, FormControl, MenuItem, TextField } from '@mui/material'
import EntityService from 'api/services/entity.service'
import { useFormik } from 'formik'
import { useAlert } from 'hooks/useAlert'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { entityFormSchema } from 'utils/schemas/userFormSchema'

const Entity = () => {
  const { setAlert } = useAlert()
  const entity = useSelector(store => store.userManagement.user.entity)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [industries, setIndustries] = useState([])

  const { dirty, errors, isValid, touched, values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: entityFormSchema,
    onSubmit: async vals => {
      setLoading(true)
      try {
        await EntityService.update(entity.id, vals)
        setAlert(true, 'success', 'Actualizado con éxito, recargando...')
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } catch (error) {
        console.error(error)
        setAlert(true, 'error', 'Ocurrió un error al guardar cambios, intenta de nuevo más tarde')
        setLoading(false)
      }
    }
  })

  const getIndustries = async () => {
    try {
      const resp = await EntityService.getIndustries()
      setIndustries(resp)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setIsButtonDisabled(!(isValid && dirty))
  }, [dirty, isValid])

  useEffect(() => {
    getIndustries()
  }, [])

  return (
    <>
      <h2>Mi Empresa</h2>
      <Box sx={{ marginTop: '1rem' }}>
        <FormControl>
          <TextField
            name="name"
            label="Nombre"
            variant="outlined"
            size="small"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </FormControl>
        <FormControl>
          <TextField
            select
            name="industry_id"
            label="Industria"
            variant="outlined"
            size="small"
            value={values.industry_id}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.industry_id && errors.industry_id)}
            helperText={touched.industry_id && errors.industry_id}>
            {industries.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormControl>
        <Button
          variant="contained"
          sx={{ marginBottom: '2rem', width: '10rem' }}
          disabled={isButtonDisabled || loading}
          onClick={handleSubmit}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Guardar cambios'}
        </Button>
      </Box>
    </>
  )
}

export default Entity
