import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  Box,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Divider,
  IconButton,
  TableBody,
  TableHead,
  Alert,
  Grid
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DateRangeIcon from '@mui/icons-material/DateRange'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined'
import { useAuth } from 'hooks/useAuth'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { risksSettingsSchema } from 'utils/schemas/riskSchema'
import ProjectService from 'api/services/project.service'
import { useInputValidations } from 'hooks/useInputValidations'
import { riskColors } from 'utils/styles'
import Styles from './_ModalRisksSettings.module.scss'

const ModalRisksSettings = ({ projectId, riskSettings, open, onConfirm, onCancel }) => {
  const { isSystemObserver } = useAuth()
  const { isProjectObserver } = useProjectRoles()
  const { onKeyPressValidateDecimalNumber } = useInputValidations()

  const [loading, setLoading] = useState(false)
  const [risksSettings, setRisksSettings] = useState({
    riskCostImpacts: [],
    riskTimeImpacts: [],
    riskProbabilities: [],
    riskSeverityLimits: {
      low: '',
      medium: '',
      high: ''
    }
  })

  const handleSaveRiskSettings = async values => {
    try {
      const resp = await ProjectService.updateRiskSettings(projectId, values)
      setLoading(false)
      onConfirm('success', resp)
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }
  const { errors, touched, values, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: risksSettings,
    enableReinitialize: true,
    validationSchema: risksSettingsSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSaveRiskSettings(vals)
    }
  })

  useEffect(() => {
    if (riskSettings) {
      setRisksSettings(riskSettings)
    }
  }, [riskSettings])

  const handleIsErrorArray = (arrayName, index, key) => {
    return Boolean(
      touched?.[arrayName]?.length > 0
        ? errors?.[arrayName]?.length > 0 && touched[arrayName]?.[index]?.[key] && errors[arrayName][index]?.[key]
        : errors?.[arrayName]?.length > 0 && errors[arrayName]?.[index]?.[key]
    )
  }

  const handleHelperTextArray = (arrayName, index, key) => {
    return touched?.[arrayName]?.length > 0
      ? errors?.[arrayName]?.length > 0 && touched?.[arrayName]?.[index]?.[key] && errors[arrayName][index]?.[key]
      : errors?.[arrayName]?.length > 0 && errors?.[arrayName]?.[index]?.[key]
  }

  const handleBlurValue = e => {
    handleBlur(e)
    e.target.value !== '' && setFieldValue(e.target.name, String(Number(e.target.value).toFixed(2)))
  }

  const handleChangeValue = e => {
    if (e.target.value === '' || Number(e.target.value) <= 1) {
      handleChange(e)
    }
  }

  useEffect(() => {
    if (open) resetForm()
  }, [open])

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description" maxWidth="600px">
      <DialogTitle className={Styles.modal_header}>
        Configuración de Riesgos
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }} className={Styles.modal_body}>
        <Alert severity="info" sx={{ marginBottom: '2rem' }}>
          Al modificar cualquier valor se aplicarán los cambios a todos los riesgos registrados en este Proyecto.
          Asegúrate de tener ésto en cuenta al realizar modificaciones.
        </Alert>
        <Box className={Styles.table__title}>
          <Divider
            textAlign="left"
            sx={{
              '& .MuiDivider-wrapper': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem'
              }
            }}>
            <TrafficOutlinedIcon sx={{ marginRight: '.5rem' }} fontSize="medium" />
            Límites de Severidad
          </Divider>
        </Box>
        <Grid container sx={{ marginBottom: '2rem' }}>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box className={Styles.severity_badge} sx={{ backgroundColor: riskColors.green }} />
              <Box sx={{ marginRight: '1rem' }}>Bajo</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                required
                name="riskSeverityLimits.low"
                size="small"
                variant="outlined"
                value={values.riskSeverityLimits.low}
                inputProps={{
                  maxLength: 4
                }}
                onBlur={handleBlur}
                onChange={handleChangeValue}
                onKeyDown={onKeyPressValidateDecimalNumber}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box className={Styles.severity_badge} sx={{ backgroundColor: riskColors.yellow }} />
              <Box sx={{ marginRight: '1rem' }}>Medio</Box>
              <TextField
                disabled={isSystemObserver || isProjectObserver}
                required
                name="riskSeverityLimits.medium"
                size="small"
                variant="outlined"
                value={values.riskSeverityLimits.medium}
                inputProps={{
                  maxLength: 4
                }}
                onBlur={handleBlur}
                onChange={handleChangeValue}
                onKeyDown={onKeyPressValidateDecimalNumber}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box className={Styles.severity_badge} sx={{ backgroundColor: riskColors.red }} />
              <Box sx={{ marginRight: '1rem' }}>Alto</Box>
              <TextField
                disabled
                required
                name="riskSeverityLimits.high"
                size="small"
                variant="outlined"
                value={values.riskSeverityLimits.high}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={Styles.table__title}>
          <Divider
            textAlign="left"
            sx={{
              '& .MuiDivider-wrapper': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem'
              }
            }}>
            <QueryStatsOutlinedIcon sx={{ marginRight: '.5rem' }} fontSize="medium" />
            Probabilidades
          </Divider>
        </Box>
        <TableContainer
          sx={{ maxWidth: '100%', border: '0.5px solid #ddd', position: 'relative', marginBottom: '2rem' }}>
          <Table
            size="small"
            sx={{
              borderCollapse: 'unset',
              '& .MuiTableCell-root': {
                borderRight: '1px solid #eee'
              }
            }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Puntuación</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Expresión</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Descripción</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Valor</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.riskProbabilities.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.score}</TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskProbabilities[${index}].expression`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskProbabilities', index, 'expression')}
                        helperText={handleHelperTextArray('riskProbabilities', index, 'expression')}
                        value={item.expression}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskProbabilities[${index}].description`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskProbabilities', index, 'description')}
                        helperText={handleHelperTextArray('riskProbabilities', index, 'description')}
                        value={item.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskProbabilities[${index}].value`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskProbabilities', index, 'value')}
                        helperText={handleHelperTextArray('riskProbabilities', index, 'value')}
                        value={item.value}
                        inputProps={{
                          maxLength: 4
                        }}
                        onBlur={handleBlurValue}
                        onChange={handleChangeValue}
                        onKeyDown={onKeyPressValidateDecimalNumber}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={Styles.table__title}>
          <Divider
            textAlign="left"
            sx={{
              '& .MuiDivider-wrapper': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem'
              }
            }}>
            <MonetizationOnOutlinedIcon sx={{ marginRight: '.5rem' }} fontSize="medium" />
            Impactos en Costo
          </Divider>
        </Box>
        <TableContainer
          sx={{ maxWidth: '100%', border: '0.5px solid #ddd', position: 'relative', marginBottom: '2rem' }}>
          <Table
            size="small"
            sx={{
              borderCollapse: 'unset',
              '& .MuiTableCell-root': {
                borderRight: '1px solid #eee'
              }
            }}>
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid #eee', backgroundColor: 'blue' }}>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Puntuación</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Expresión</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Descripción</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Valor</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.riskCostImpacts.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.score}</TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskCostImpacts[${index}].expression`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskCostImpacts', index, 'expression')}
                        helperText={handleHelperTextArray('riskCostImpacts', index, 'expression')}
                        value={item.expression}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskCostImpacts[${index}].description`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskCostImpacts', index, 'description')}
                        helperText={handleHelperTextArray('riskCostImpacts', index, 'description')}
                        value={item.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskCostImpacts[${index}].value`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskCostImpacts', index, 'value')}
                        helperText={handleHelperTextArray('riskCostImpacts', index, 'value')}
                        value={item.value}
                        inputProps={{
                          maxLength: 4
                        }}
                        onBlur={handleBlurValue}
                        onChange={handleChangeValue}
                        onKeyDown={onKeyPressValidateDecimalNumber}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={Styles.table__title}>
          <Divider
            textAlign="left"
            sx={{
              '& .MuiDivider-wrapper': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.2rem'
              }
            }}>
            <DateRangeIcon sx={{ marginRight: '.5rem' }} fontSize="medium" />
            Impactos en Tiempo
          </Divider>
        </Box>
        <TableContainer
          sx={{ maxWidth: '100%', border: '0.5px solid #ddd', position: 'relative', marginBottom: '2rem' }}>
          <Table
            size="small"
            sx={{
              borderCollapse: 'unset',
              '& .MuiTableCell-root': {
                borderRight: '1px solid #eee'
              }
            }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Puntuación</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Expresión</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Descripción</b>
                </TableCell>
                <TableCell sx={{ borderBottom: '2px solid #ccc' }}>
                  <b>Valor</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.riskTimeImpacts.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.score}</TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskTimeImpacts[${index}].expression`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskTimeImpacts', index, 'expression')}
                        helperText={handleHelperTextArray('riskTimeImpacts', index, 'expression')}
                        value={item.expression}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskTimeImpacts[${index}].description`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskTimeImpacts', index, 'description')}
                        helperText={handleHelperTextArray('riskTimeImpacts', index, 'description')}
                        value={item.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={isSystemObserver || isProjectObserver}
                        required
                        name={`riskTimeImpacts[${index}].value`}
                        size="small"
                        variant="outlined"
                        error={handleIsErrorArray('riskTimeImpacts', index, 'value')}
                        helperText={handleHelperTextArray('riskTimeImpacts', index, 'value')}
                        value={item.value}
                        inputProps={{
                          maxLength: 4
                        }}
                        onBlur={handleBlurValue}
                        onChange={handleChangeValue}
                        onKeyDown={onKeyPressValidateDecimalNumber}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className={Styles.modal_footer}>
        {!isSystemObserver && !isProjectObserver && (
          <>
            <Button sx={{ width: '10rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
              Cancelar
            </Button>
            <Button sx={{ width: '10rem' }} disabled={loading} variant="contained" onClick={handleSubmit}>
              {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Guardar cambios'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

ModalRisksSettings.propTypes = {
  projectId: PropTypes.string,
  riskSettings: PropTypes.object,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModalRisksSettings
