import { createSlice } from '@reduxjs/toolkit'

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    user: undefined
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    clearUser: state => {
      state.user = undefined
    }
  }
})

export const { clearUser, setUser } = userManagementSlice.actions

export default userManagementSlice.reducer
