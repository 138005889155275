import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, TextField, InputAdornment, IconButton, Divider, MenuItem } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import UserService from 'api/services/user.service'
import SideModal from 'components/SideModal/SideModal'
import User from 'models/User'
import { ROLE_DEVELOPER, userRoles } from 'utils/constants'
import { userCreationFormSchema, userUpdateFormSchema } from 'utils/schemas/userFormSchema'
import Styles from './_SideModalUser.module.scss'

const SideModalUser = ({ actionType, open, selectedUser, onCancel, onConfirm }) => {
  const authUser = useSelector(store => store.userManagement.user)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(new User())
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const roles =
    authUser?.role_id === ROLE_DEVELOPER ? userRoles : userRoles.filter(role => role.value !== ROLE_DEVELOPER)

  const handleSaveUser = async user => {
    try {
      actionType === 'create' ? await UserService.store(user) : await UserService.update(user.id, user)
      setLoading(false)
      onConfirm('success')
    } catch (error) {
      console.error(error)
      setLoading(false)
      onConfirm('error')
    }
  }

  const { errors, touched, values, handleBlur, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: actionType === 'create' ? userCreationFormSchema : userUpdateFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSaveUser(vals)
    }
  })

  useEffect(() => {
    if (open) {
      if (selectedUser) {
        setUser(selectedUser)
      } else {
        setUser(new User())
      }
      resetForm()
    }
  }, [open])

  return (
    <SideModal
      title={`${actionType === 'create' ? 'Nuevo' : 'Editar'} Usuario`}
      onConfirmText={`${actionType === 'create' ? 'Crear' : 'Guardar'} Usuario`}
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      <>
        <FormControl className={Styles.input}>
          <TextField
            name="name"
            label="Nombre"
            size="small"
            variant="outlined"
            value={values.name}
            error={Boolean(touched?.name && errors.name)}
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="last_name"
            label="Apellidos"
            size="small"
            variant="outlined"
            value={values.last_name}
            error={Boolean(touched?.last_name && errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="email"
            label="Email"
            size="small"
            variant="outlined"
            value={values.email}
            error={Boolean(touched?.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="cellphone"
            label="Telefono celular"
            size="small"
            variant="outlined"
            value={values.cellphone}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="phone"
            label="Telefono fijo"
            size="small"
            variant="outlined"
            value={values.phone}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="role_id"
            label="Rol"
            size="small"
            variant="outlined"
            select
            value={values.role_id}
            onChange={handleChange}>
            {roles.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {actionType === 'create' && (
          <>
            <Divider sx={{ marginBottom: '1.5rem' }} />
            <FormControl className={Styles.input}>
              <TextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                size="small"
                variant="outlined"
                value={values.password}
                error={Boolean(touched?.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="password_confirmation"
                type={showPasswordConfirmation ? 'text' : 'password'}
                label="Confirm Password"
                size="small"
                variant="outlined"
                value={values.password_confirmation}
                error={Boolean(touched?.password_confirmation && errors.password_confirmation)}
                helperText={touched.password_confirmation && errors.password_confirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end">
                        {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
          </>
        )}
      </>
    </SideModal>
  )
}

SideModalUser.propTypes = {
  actionType: PropTypes.string,
  open: PropTypes.bool,
  selectedUser: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalUser
