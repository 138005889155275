import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Box, Button, Divider } from '@mui/material'
import ModalResetPassword from 'components/ModalResetPassword/ModalResetPassword'
import { useAlert } from 'hooks/useAlert'
import Styles from './_Profile.module.scss'
import SideModalProfile from './components/SideModalProfile/SideModalProfile'

const Profile = () => {
  const { setAlert } = useAlert()
  const user = useSelector(store => store.userManagement.user)
  const [showSideModalProfile, setShowSideModalProfile] = useState(false)
  const [showModalResetPassword, setShowModalResetPassword] = useState(false)

  const getCapitalLetters = user => {
    return `${user.name.charAt(0)}${user.last_name?.charAt(0)}`
  }

  const handleSaveProfile = result => {
    setShowSideModalProfile(false)
    if (result === 'success') {
      setAlert(true, 'success', 'Perfil actualizado con éxito')
    } else {
      setAlert(true, 'error', 'Ocurrió un error al guardar los cambios, intenta de nuevo más tarde')
    }
  }

  const handleSavePassword = result => {
    setShowModalResetPassword(false)
    if (result === 'success') {
      setAlert(true, 'success', 'Contraseña actualizada con éxito')
    } else {
      setAlert(true, 'error', 'Ocurrió un error al actualizar la contraseña, intenta de nuevo más tarde')
    }
  }

  return (
    <>
      <h2>Mi Perfil</h2>
      {user && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '30rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '1.5rem' }}>
              <Avatar sx={{ width: 72, height: 72, margin: 1 }}>{getCapitalLetters(user)}</Avatar>
              <b>
                {user.name} {user.last_name}
              </b>
              <span>{user.role?.name}</span>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '.65rem' }}
                  onClick={() => setShowSideModalProfile(true)}>
                  Editar perfil
                </Button>
              </Box>
              <Divider sx={{ margin: '1rem 0', borderWidth: '2px' }} />
              <Box className={Styles.profile__item}>
                <small>Nombre</small>
                <span>
                  {user.name} {user.last_name}
                </span>
              </Box>
              <Divider sx={{ margin: '.75rem 0' }} />
              <Box className={Styles.profile__item}>
                <small>Email</small>
                <span>{user.email}</span>
              </Box>
              <Divider sx={{ margin: '.75rem 0' }} />
              <Box className={Styles.profile__item}>
                <small>Telefono celular</small>
                <span>{user.cellphone}</span>
              </Box>
              <Divider sx={{ margin: '.75rem 0' }} />
              <Box className={Styles.profile__item}>
                <small>Telefono fijo</small>
                <span>{user.phone}</span>
              </Box>
              <Divider sx={{ marginBottom: '1rem', marginTop: '2rem', borderWidth: '2px' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box className={Styles.profile__item}>
                  <small>Contraseña</small>
                  <span>Actualiza tu contraseña</span>
                </Box>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '.65rem' }}
                  onClick={() => setShowModalResetPassword(true)}>
                  Cambiar contraseña
                </Button>
              </Box>
              <Divider sx={{ margin: '.75rem 0' }} />
            </Box>
          </Box>
        </Box>
      )}
      {showSideModalProfile && (
        <SideModalProfile
          open={showSideModalProfile}
          selectedUser={user}
          onCancel={() => setShowSideModalProfile(false)}
          onConfirm={handleSaveProfile}
        />
      )}
      {showModalResetPassword && (
        <ModalResetPassword
          open={showModalResetPassword}
          onCancel={() => setShowModalResetPassword(false)}
          onConfirm={handleSavePassword}
        />
      )}
    </>
  )
}

export default Profile
